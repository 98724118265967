import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import organisationsAPI from '@/api/organisations';
import useAuth from '@/hooks/useAuth';

import Button, { BUTTON_KIND } from '../Button';
import Modal from '../Modals/Modal';

export function DeleteGroup({ groupID }: { groupID: number | undefined }) {
  const { orgID } = useAuth();
  const history = useHistory();

  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync: deleteGroup, isLoading: isDeletingGroup } = useMutation({
    mutationFn: deleteOrganisationGroup,
    onSuccess: () => {
      history.push('/groups', {
        success: 'Group has been deleted successfully.',
      });
    },
    onError: () => {
      setIsModalOpen(true);
    },
  });

  async function deleteOrganisationGroup() {
    if (orgID === undefined) {
      throw new Error('No org ID');
    }

    if (groupID === undefined) {
      throw new Error('Group ID cannot be empty');
    }

    await organisationsAPI.deleteOrganisationGroup(orgID, groupID);
  }

  return (
    <>
      <div className="flex justify-end">
        <ul className="divide-y divide-gray-200">
          <Button
            buttonText="Delete"
            kind={BUTTON_KIND.LIGHT_RED}
            onClick={() => setIsModalOpen(true)}
            disabled={groupID === undefined}
          />
        </ul>
      </div>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        dialogTitle={
          <span className="font-bold">
            Warning:&nbsp;
            <span className="font-medium">
              Deleting this group is permanent.
            </span>
          </span>
        }
        dialogDescription="By proceeding, you acknowledge that you will permanently lose access to this group, associated data and analytics. Profile and group managers will not be deleted."
        successButtonText="Delete group"
        successButtonKind={BUTTON_KIND.RED}
        isLoading={isDeletingGroup}
        onSuccess={deleteGroup}
        checkbox={isDeleteConfirmed}
        setCheckbox={setIsDeleteConfirmed}
        isDisabled={!isDeleteConfirmed}
        checkboxDescription="I am sure I want to delete this group."
      />
    </>
  );
}
