import { Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import {
  ChevronDownIcon,
  ClipboardListIcon,
  IdentificationIcon,
  KeyIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline';

import { initFlowbite } from 'flowbite';

import Logo from '@/assets/logos/primary.svg';
import Analytics from '@/components/Icons/Analytics';
import Card from '@/components/Icons/Card';
import Contacts from '@/components/Icons/Contacts';
import Logout from '@/components/Icons/Logout';
import Settings from '@/components/Icons/Settings';
import Shop from '@/components/Icons/Shop';
import classNames from '@/helpers/classNames';
import useAppState, { SettingsTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import SubscriptionStatus from '@/types/SubscriptionStatus';

import Button from './Button';
import DesignWidget from './DesignWidget';
import DashboardIcon from './Icons/DashboardIcon';

interface ISidebarNavigation {
  pageName: string;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarNavigation: React.FC<ISidebarNavigation> = ({
  pageName,
  setSidebarOpen,
}) => {
  useEffect(() => {
    initFlowbite();
  }, []);

  const {
    userScope,
    isUserViewForAdmin,
    getCurrentUser,
    logout,
    userRole,
    adminRole,
    platformSubscriptionStatus,
  } = useAuth();
  const {
    settingsTab,
    shopTab,
    selectSettingsTab,
    selectShopTab,
    expandedNavItems,
    setExpandedNavItems,
  } = useAppState();
  const history = useHistory();

  const isSuperUser = userScope === 'admin';
  const hasSuperUserAccess = isSuperUser && adminRole === null;
  const hasSalesAccess =
    (isSuperUser && adminRole === 'sale') || adminRole === null;
  const hasDesignAccess =
    (isSuperUser && adminRole === 'design') || adminRole === null;

  const hasOrgAdminAccess = userScope === 'user' && userRole === 'org_admin';
  const disableNavigation =
    platformSubscriptionStatus === SubscriptionStatus.INACTIVE;

  const AUTH = {
    superUser: 'admin',
    user: 'user',
    undefined,
  };

  const navigation = [
    {
      name: 'Home',
      href: '/home',
      icon: DashboardIcon,
      current: pageName === 'HomePage',
      authen: [AUTH.user],
      newFeature: true,
    },
    {
      name: 'My profiles',
      href: '/',
      onClick: () => {
        history.push('/');
      },
      icon: Card,
      authen: [AUTH.user],
      defaultOpen: ['My Profiles', 'Profile Grouping'].includes(pageName),
      children: [
        {
          name: 'All Profiles',
          href: '/',
          current:
            pageName === 'My Profiles' &&
            window.location.pathname !== '/groups',
          authen: [AUTH.user],
        },
        {
          name: 'Groups',
          href: '/groups',
          current: window.location.pathname === '/groups',
          authen: [AUTH.user],
        },
      ],
    },
    {
      name: 'Contacts',
      href: '/contact-list',
      icon: Contacts,
      current: pageName === 'Contacts',
      authen: [AUTH.user],
    },
    ...(userRole === 'org_admin'
      ? [
          {
            name: 'Analytics',
            href: '/analytics2',
            icon: Analytics,
            current: pageName === 'Analytics',
            authen: [AUTH.user],
          },
        ]
      : [
          {
            name: 'Analytics',
            href: '/analytics',
            icon: Analytics,
            current: pageName === 'Analytics',
            authen: [AUTH.user],
          },
        ]),
    ...(isUserViewForAdmin
      ? [
          {
            name: 'Advanced Analytics',
            href: '/analytics2',
            icon: Analytics,
            current: pageName === 'Advanced Analytics',
            authen: [AUTH.user],
          },
        ]
      : []),
    ...(userRole === 'org_admin'
      ? [
          {
            name: 'Integrations',
            href: '/integrations',
            icon: SparklesIcon,
            current: pageName === 'Integrations',
            authen: [AUTH.user],
          },
        ]
      : []),
    ...(userRole === 'org_admin'
      ? [
          {
            name: 'Shop',
            href: '/shop',
            onClick: () => {
              history.push('/shop');
              selectShopTab(0);
            },
            icon: Shop,
            authen: [AUTH.user],
            defaultOpen: ['Shop'].includes(pageName),
            children: [
              {
                name: 'Orders',
                href: '/shop',
                onClick: () => {
                  selectShopTab(0);
                },
                current: pageName === 'Shop' && shopTab === 0,
                authen: [AUTH.user],
              },
              {
                name: 'Shop tapt products',
                href: '/shop',
                onClick: () => {
                  selectShopTab(1);
                },
                current: pageName === 'Shop' && shopTab === 1,
                authen: [AUTH.user],
              },
              {
                name: 'Reorder',
                href: '/shop',
                onClick: () => {
                  selectShopTab(2);
                },
                current: pageName === 'Shop' && shopTab === 2,
                authen: [AUTH.user],
              },
            ],
          },
        ]
      : []),
    ...(hasOrgAdminAccess
      ? [
          {
            name: 'Settings',
            href: '/settings',
            onClick: () => {
              history.push('/settings');
              selectSettingsTab(SettingsTab.GENERAL);
            },
            icon: Settings,
            authen: [AUTH.user],
            defaultOpen: !disableNavigation && ['Settings'].includes(pageName),
            children: [
              {
                name: 'General',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.GENERAL);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.GENERAL,
                authen: [AUTH.user],
              },
              {
                name: 'Organisation',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.ORGANISATION);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.ORGANISATION,
                authen: [AUTH.user],
              },
              {
                name: 'Profile design',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.PROFILE);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.PROFILE,
                authen: [AUTH.user],
              },
              {
                name: 'Contact exchange',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.CONTACT_EXCHANGE);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.CONTACT_EXCHANGE,
                authen: [AUTH.user],
              },
              {
                name: 'Profile & editing',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.PROFILE_EDITING);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.PROFILE_EDITING,
                authen: [AUTH.user],
              },
              {
                name: 'Manage Plan',
                href: '/settings',
                onClick: () => {
                  selectSettingsTab(SettingsTab.MANAGE_PLAN);
                },
                current:
                  pageName === 'Settings' &&
                  settingsTab === SettingsTab.MANAGE_PLAN,
                authen: [AUTH.user],
              },
            ],
          },
        ]
      : []),
  ];

  const superuserNavigation = [
    ...(hasDesignAccess
      ? [
          {
            name: 'Cards',
            href: '/',
            icon: Card,
            current: pageName === 'Cards',
            authen: [AUTH.superUser],
          },
        ]
      : []),
    {
      name: 'Profiles',
      href: '/profiles-list',
      icon: IdentificationIcon,
      current: pageName === 'Profiles',
      authen: [AUTH.superUser],
    },
    ...(hasDesignAccess
      ? [
          {
            name: 'Activation Keys',
            href: '/activation-keys',
            icon: KeyIcon,
            current: pageName === 'Activation Keys',
            authen: [AUTH.superUser],
          },
        ]
      : []),
    {
      name: 'Organisations',
      href: '/organisations-list',
      icon: UserGroupIcon,
      current: pageName === 'Organisations',
      authen: [AUTH.superUser],
    },
    {
      name: 'Contacts',
      href: '/contact-list',
      icon: Contacts,
      current: pageName === 'Contacts',
      authen: [AUTH.superUser],
    },
    {
      name: 'Analytics',
      href: '/analytics',
      icon: Analytics,
      current: pageName === 'Analytics',
      authen: [AUTH.superUser],
    },
    ...(isUserViewForAdmin || isSuperUser
      ? [
          {
            name: 'Superuser Analytics',
            href: '/analytics2',
            onClick: () => {
              history.push('/analytics2');
            },
            icon: Analytics,
            defaultOpen: [
              'Superuser Analytics',
              'Organisation Analytics',
            ].includes(pageName),
            authen: [AUTH.superUser],
            newFeature: true,
            children: isSuperUser
              ? [
                  {
                    name: 'Super admin analytics',
                    href: '/analytics2',
                    icon: <></>,
                    current: pageName === 'Superuser Analytics',
                    authen: [AUTH.superUser],
                  },
                  {
                    name: 'Collection analytics',
                    href: '/org-analytics',
                    icon: <></>,
                    current: pageName === 'Collection Analytics',
                    authen: [AUTH.superUser],
                  },
                ]
              : null,
          },
        ]
      : []),
    ...(isSuperUser
      ? [
          {
            name: 'Orders',
            href: '/orders-v2',
            icon: ClipboardListIcon,
            current: pageName === 'Orders' || pageName === 'Shopify Orders',
            authen: [AUTH.superUser],
            hideInSideBar: false,
          },
        ]
      : []),
    ...(hasSalesAccess
      ? [
          {
            name: 'Pilots',
            href: '/pilots',
            icon: PaperAirplaneIcon,
            current: pageName === 'Pilots',
            authen: [AUTH.superUser],
          },
        ]
      : []),
    ...(hasSuperUserAccess
      ? [
          {
            name: 'Settings',
            href: '/settings',
            icon: Settings,
            current: pageName === 'Settings',
            authen: [AUTH.superUser],
          },
        ]
      : []),
  ];

  const checkAuth = (navigationAuthen: any) => {
    return navigationAuthen.includes(userScope);
  };

  const renderNavigationItem = (item: any, index: number) => {
    const linkActiveClasses = item.current
      ? 'bg-brand-100 text-brand-500'
      : 'text-gray-900 hover:bg-brand-100 hover:text-brand-500';
    const linkClasses = disableNavigation
      ? 'text-gray-400 pointer-events-none'
      : linkActiveClasses;

    const chevronClasses = disableNavigation
      ? 'text-gray-400 pointer-events-none'
      : 'text-gray-900 group-hover:text-brand-500';

    return !item.children ? (
      <Link
        key={`${item.name}-${index}`}
        to={item.href}
        onClick={item.onClick}
        className={classNames(
          linkClasses,
          'group flex items-center px-3 py-2 text-base leading-6 md:text-sm md:leading-5 font-medium rounded-md transition',
        )}
      >
        {item.icon && (
          <item.icon
            className="mr-3 flex-shrink-0 h-6 w-6"
            aria-hidden="true"
          />
        )}
        {item.name}
        {item.newFeature && (
          <span
            className={classNames(
              item.current
                ? 'bg-white text-brand-500'
                : 'bg-brand-400 text-white',
              'text-xs font-medium rounded-full px-3 py-1 ml-auto',
            )}
          >
            New!
          </span>
        )}
      </Link>
    ) : (
      <Disclosure
        as="div"
        defaultOpen={
          item.defaultOpen || expandedNavItems.includes(item.name as string)
        }
      >
        {({ open }) => (
          <>
            <div
              className={classNames(
                disableNavigation ? '' : 'hover:bg-brand-100',
                'flex justify-between items-center  rounded-md px-3 py-2  gap-x-3 group',
              )}
            >
              <Link
                to={item.href}
                onClick={() => {
                  setExpandedNavItems(state => {
                    return state.includes(item.name as string)
                      ? state
                      : [...state, item.name as string];
                  });
                  return item.onClick();
                }}
                className={classNames(
                  linkClasses,
                  'flex items-center  text-left text-base  leading-6 md:text-sm md:leading-5 font-medium  min-w-[80%]',
                )}
              >
                {item.icon && (
                  <item.icon
                    className="mr-3 flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
              <Disclosure.Button
                onClick={() => {
                  setExpandedNavItems(state => {
                    if (!open) {
                      return state.includes(item.name as string)
                        ? state
                        : [...state, item.name as string];
                    }
                    return state.filter(i => i !== item.name);
                  });
                }}
                className={classNames(
                  linkClasses,
                  'flex items-center w-full rounded-md gap-x-3 leading-6 md:leading-5',
                )}
              >
                <ChevronDownIcon
                  className={classNames(
                    chevronClasses,
                    open ? 'transform rotate-180' : '',
                    'ml-auto h-5 w-5 shrink-0',
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </div>
            <Disclosure.Panel as="ul" className="mt-1 space-y-1">
              {item.children.map((subItem: any) => (
                <li key={subItem.name}>
                  {/* 44px */}
                  <Link
                    to={subItem.href}
                    onClick={subItem.onClick}
                    className={classNames(
                      subItem.current
                        ? 'bg-brand-100 text-brand-500'
                        : 'text-gray-900 hover:bg-brand-100',
                      'block rounded-md py-2 pr-2 pl-12 text-xs leading-6',
                    )}
                  >
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="relative flex-1 flex flex-col justify-between max-w-xs w-full max-h-full bg-white">
      <div className="pb-4">
        <div className="pt-2 sm:pt-3 md:pt-8 px-2 sm:px-3 md:px-5 flex items-center sticky top-0">
          <button
            className="inline-flex lg:hidden items-center justify-center h-10 w-10 rounded-full focus:outline-none"
            onClick={() => setSidebarOpen(false)}
            type="button"
          >
            <span className="sr-only">Close sidebar</span>
            <XIcon className="h-6 w-6 text-gray-700" aria-hidden="true" />
          </button>
          <div className="flex-shrink-0 inline-flex items-center">
            <Link to="/">
              <img className="h-7 w-auto" src={Logo} alt="Tapt" />
            </Link>
            {import.meta.env.VITE_DISPLAY_ENV
              ? ` (${import.meta.env.VITE_DISPLAY_ENV})`
              : ``}
          </div>
        </div>
        <div className="h-[70vh] overflow-y-auto mt-5">
          {AUTH.superUser && (
            <nav className="mt-5 px-2 space-y-1">
              {superuserNavigation.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {checkAuth(item.authen) &&
                      !item.hideInSideBar &&
                      renderNavigationItem(item, index)}
                  </Fragment>
                );
              })}
            </nav>
          )}
          {AUTH.user && (
            <nav className="px-2 mt-5 space-y-1">
              {navigation.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {checkAuth(item.authen) &&
                      renderNavigationItem(item, index)}
                  </Fragment>
                );
              })}
            </nav>
          )}
          <DesignWidget />
        </div>
      </div>
      <div>
        <div className="flex p-4 border-t border-gray-200 md:border-t relative bottom-[1rem]">
          <div className="flex items-center">
            <div className="px-2 space-y-4 w-full">
              {hasOrgAdminAccess && (
                <Link
                  to="/settings#plan"
                  onClick={() => selectSettingsTab(SettingsTab.MANAGE_PLAN)}
                  className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
                >
                  <ViewGridIcon
                    className="mr-3 flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  Manage Plan
                </Link>
              )}
              <a
                href="https://help.tapt.io/"
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
              >
                <QuestionMarkCircleIcon
                  className="mr-3 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                Support
              </a>
              <button
                className="text-gray-500 hover:text-gray-700 flex items-center text-sm font-normal rounded-md transition"
                onClick={() => logout()}
                type="button"
              >
                <Logout
                  className="mr-3 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                Logout
              </button>
              {isUserViewForAdmin && (
                <Button
                  buttonText="Switch to Admin view"
                  onClick={getCurrentUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SidebarNavigation as default };
