import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ErrorAlert } from '@/components/Alert';
import Button from '@/components/Button';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import LayoutStandard from '@/components/LayoutStandard';
import { validateEmail } from '@/helpers/validate';
import useAuth from '@/hooks/useAuth';
import usePageHeight from '@/hooks/usePageHeight';

interface ILoginPage {
  location: {
    state: {
      activationKey: string;
    };
  };
}

const ShopifyLoginPage: React.FC<ILoginPage> = ({ location }) => {
  const { shopifyLogin, redirectToShopify, loading, authError, user } =
    useAuth();

  const [email, setEmail] = useState(localStorage.getItem('mfa_email') || '');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const activationKey = location.state?.activationKey;
  const pageHeight = usePageHeight();

  const handleClick = () => {
    // set errors back to false
    setEmailError(false);
    if (validateEmail(email)) {
      setEmailError(false);
      shopifyLogin({
        email,
        password,
        rememberMe,
      });
    } else {
      setEmailError(true);
    }
  };

  useEffect(() => {
    // redirect if user is already logged in
    if (user) {
      redirectToShopify();
    }
  }, []);

  return (
    <LayoutStandard heading="Log in to your Tapt account">
      <div
        className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        style={{ maxHeight: pageHeight }}
      >
        <div className="hidden lg:block bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          {authError && <ErrorAlert message="Invalid email or password" />}
          {emailError && <ErrorAlert message="Please enter a valid email" />}
          <div className="space-y-6">
            <Input
              label="Email address"
              type="email"
              value={email}
              onChange={setEmail}
              error={emailError}
            />

            <Input
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />

            <div className="flex items-center justify-between">
              <InputCheckbox
                id="remember-me"
                label="Remember me"
                value={rememberMe}
                onChange={setRememberMe}
              />
              <Link to="/reset-password">Forgot your password?</Link>
            </div>

            <div>
              <Button
                buttonText="Log in"
                onClick={() => {
                  if (!loading) handleClick();
                }}
                fullWidth
                loading={loading}
              />
            </div>
          </div>
        </div>
        <div className="lg:hidden bg-white py-2 px-4 sm:rounded-lg sm:px-10">
          {authError && <ErrorAlert message="Invalid email or password" />}
          {emailError && <ErrorAlert message="Please enter a valid email" />}
          <div className="space-y-6">
            <Input
              label="Email address"
              type="email"
              value={email}
              onChange={setEmail}
              error={emailError}
            />

            <Input
              label="Password"
              type="password"
              value={password}
              onChange={setPassword}
            />

            <div className="flex items-center justify-between">
              <InputCheckbox
                id="remember-me"
                label="Remember me"
                value={rememberMe}
                onChange={setRememberMe}
              />
              <Link to="/reset-password">Forgot your password?</Link>
            </div>

            <div>
              <Button
                buttonText="Log in"
                onClick={() => {
                  if (!loading) handleClick();
                }}
                fullWidth
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutStandard>
  );
};

export default ShopifyLoginPage;
