import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { ErrorAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import LayoutStandard from '@/components/LayoutStandard';
import SsoLogin from '@/components/LoginPage/SsoLogin';
import { validateEmail } from '@/helpers/validate';
import useAuth from '@/hooks/useAuth';
import usePageHeight from '@/hooks/usePageHeight';

interface ILoginPage {
  location: {
    state:
      | {
          activationKey: string;
        }
      | undefined;
  };
}

function LoginPage({ location }: ILoginPage) {
  const { login, loading, authError, user } = useAuth();
  const pageHeight = usePageHeight();
  const activationKey = location.state?.activationKey;

  const [email, setEmail] = useState(localStorage.getItem('mfa_email') || '');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showSsoLogin, setShowSsoLogin] = useState(false);

  function onLogin() {
    // set errors back to false
    setEmailError(false);

    if (validateEmail(email)) {
      setEmailError(false);

      login({
        email,
        password,
        rememberMe,
        activationKeyFromLogin: activationKey,
      });
    } else {
      setEmailError(true);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onLogin();
    }
  }

  // redirect if user is already logged in
  if (user) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            activationKeyFromLogin: activationKey,
          },
        }}
      />
    );
  }

  return (
    <LayoutStandard heading="Log in to your Tapt account">
      <div
        className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        style={{ maxHeight: pageHeight }}
      >
        {!showSsoLogin && (
          <>
            <div className="hidden lg:block bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
              <div className="space-y-6">
              {authError && <ErrorAlert message="Invalid email or password" />}
              {emailError && (
                <ErrorAlert message="Please enter a valid email" />
              )}

                <Input
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={setEmail}
                  error={emailError}
                />
                <Input
                  label="Password"
                  type="password"
                  value={password}
                  onChange={setPassword}
                  onKeyDown={handleKeyPress}
                />

                <div className="flex items-center justify-between">
                  <InputCheckbox
                    id="remember-me"
                    label="Remember me"
                    value={rememberMe}
                    onChange={setRememberMe}
                  />
                  <Link to="/reset-password">Forgot your password?</Link>
                </div>

                <div>
                  <Button
                    buttonText="Log in"
                    onClick={onLogin}
                    fullWidth
                    loading={loading}
                  />
                </div>

                <div>
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Log in with Single Sign-On"
                    onClick={() => setShowSsoLogin(true)}
                    fullWidth
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
            <div className="lg:hidden bg-white py-2 px-4 sm:rounded-lg sm:px-10">
              {authError && <ErrorAlert message="Invalid email or password" />}
              {emailError && (
                <ErrorAlert message="Please enter a valid email" />
              )}

              <div className="space-y-6">
                <Input
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={setEmail}
                  error={emailError}
                />
                <Input
                  label="Password"
                  type="password"
                  value={password}
                  onChange={setPassword}
                  onKeyDown={handleKeyPress}
                />

                <div className="flex items-center justify-between">
                  <InputCheckbox
                    id="remember-me"
                    label="Remember me"
                    value={rememberMe}
                    onChange={setRememberMe}
                  />
                  <Link to="/reset-password">Forgot your password?</Link>
                </div>

                <div>
                  <Button
                    buttonText="Log in"
                    onClick={onLogin}
                    fullWidth
                    loading={loading}
                  />
                </div>

                <div>
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Log in with Single Sign-On"
                    onClick={() => setShowSsoLogin(true)}
                    fullWidth
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {showSsoLogin && <SsoLogin setShowSsoLogin={setShowSsoLogin} />}
      </div>
    </LayoutStandard>
  );
}

export default LoginPage;
