import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Tab } from '@headlessui/react';

import adminAPI from '@/api/admin';
import saasAPI from '@/api/saas';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import CardsListItem from '@/components/CardsListItem';
import FileUploadButtonComponent from '@/components/FileUploadButton';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import Input from '@/components/Input';
import InputCheckbox from '@/components/InputCheckbox';
import InputSelect from '@/components/InputSelect';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Modal from '@/components/Modals/Modal';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import TabList from '@/components/TabList';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import searchParamsQuery from '@/helpers/urlHandler';
import useAppState from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import ICard from '@/types/ICard';
import { IListNew, IListPaging } from '@/types/IList';
import ISalesChannel from '@/types/ISalesChannel';
import SubscriptionStatus from '@/types/SubscriptionStatus';

interface ICardsListPage {
  location: {
    search: string;
  };
}

export enum CARD_STATUS {
  UNPROGRAMMED = 'unprogrammed',
  PROGRAMMED = 'programmed',
  PENDING_TO_PRINT = 'pending_to_print',
  CONNECTED = 'connected',
  ALL = 'all',
}

type Tab = {
  name: string;
  value: CARD_STATUS;
};

const tabs: Tab[] = [
  {
    name: 'Unprogrammed',
    value: CARD_STATUS.UNPROGRAMMED,
  },
  {
    name: 'Unconnected',
    value: CARD_STATUS.PROGRAMMED,
  },
  {
    name: 'In Progress',
    value: CARD_STATUS.PENDING_TO_PRINT,
  },
  {
    name: 'Connected',
    value: CARD_STATUS.CONNECTED,
  },
  {
    name: 'All cards',
    value: CARD_STATUS.ALL,
  },
];

const cardTypes = [
  {
    label: 'Tapt Custom',
    value: 'custom',
  },
  {
    label: 'Lite',
    value: 'lite',
  },
  {
    label: 'Tapt Black',
    value: 'black',
  },
  {
    label: 'Sticker',
    value: 'sticker',
  },
];

export const cardIsDeletable: (status: CARD_STATUS) => boolean = status => {
  const deletableStatuses = [
    CARD_STATUS.UNPROGRAMMED,
    CARD_STATUS.PROGRAMMED,
    CARD_STATUS.PENDING_TO_PRINT,
  ];

  return deletableStatuses.includes(status);
};

const CardsListPage: React.FC<ICardsListPage> = ({ location }) => {
  const { scope, uOrgID, uPlatformSubscriptionStatus } = searchParamsQuery(
    location.search,
  );
  const history = useHistory();
  const { setScopeAndOrg } = useAuth();
  const { selectCards, cardStatus, selectCardStatus } = useAppState();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [data, setData] = useState<ICard[]>();
  const [unprogrammedCount, setUnprogrammedCount] = useState<number>();
  const [programmedCount, setProgrammedCount] = useState<number>();
  const [inProgressCount, setInProgressCount] = useState<number>();
  const [connectedCount, setConnectedCount] = useState<number>();
  const [allCount, setAllCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isBulkUpdateOpen, setIsBulkUpdateOpen] = useState(false);
  const [importCSV, setImportCSV] = useState<File>();
  const [numberOfCards, setNumberOfCards] = useState('');
  const [cardType, setCardType] = useState<string>('custom');
  const [salesChannels, setSalesChannels] = useState<ISalesChannel[]>();
  const [salesChannel, setSalesChannel] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [status, setStatus] = useState(tabs[cardStatus].value);
  const [deleteIsAcknowledged, setDeleteIsAcknowledged] = useState(false);

  const setCount = (status: string, count: number) => {
    switch (status) {
      case 'unprogrammed':
        setUnprogrammedCount(count);
        break;
      case 'programmed':
        setProgrammedCount(count);
        break;
      case 'pending_to_print':
        setInProgressCount(count);
        break;
      case 'connected':
        setConnectedCount(count);
        break;
      case 'all':
        setAllCount(count);
        break;
      default:
        break;
    }
  };

  const getCount = (status: string) => {
    return {
      unprogrammed: unprogrammedCount,
      programmed: programmedCount,
      pending_to_print: inProgressCount,
      connected: connectedCount,
      all: allCount,
    }[status];
  };

  const listCards = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: ICard[]; paging: IListPaging }> => {
      const res = await adminAPI.listCards({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        status: newStatus,
        order: newOrder,
        search: newSearch,
      });

      if (!initial) {
        setData(res.data.data);
        setPage(newPage);
        setPageSize(newPageSize);
        setSort(newSort);
        setOrder(newOrder);
      }

      setCount(newStatus, res.data.paging.total_entries);

      return res.data;
    },
    [order, page, pageSize, search, sort, status],
  );

  function handleCreateCards() {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .createCards({
        count: Number(numberOfCards),
        card: {
          type: cardType,
          sales_channel_id: Number(salesChannel),
        },
      })
      .then(() => setSuccess(MESSAGES.card.create.success))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .finally(() => setIsOpen(false));
  }

  function handleCheckItem(cardID: number) {
    let arr = checkedItems;
    if (checkedItems.includes(cardID)) {
      arr = arr.filter(e => e !== cardID);
    } else {
      arr = [...checkedItems, cardID];
    }
    setCheckedItems(arr);
    selectCards(arr);
  }

  function updateCard(
    cardID: number,
    newStatus: string,
    adminNote?: string,
    profileID?: number | null,
    orgID?: number,
  ) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .updateCard(cardID, {
        card: {
          status: newStatus,
          ...(profileID !== undefined
            ? {
                profile_id: profileID,
              }
            : {}),
          ...(orgID !== undefined
            ? {
                organisation_id: orgID,
              }
            : {}),
          ...(adminNote !== undefined
            ? {
                admin_note: adminNote,
              }
            : {}),
        },
      })
      .then(() => setSuccess(MESSAGES.card.update.success(cardID)))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .then(() => listCards({ newStatus, initial: true }))
      .finally(() => setIsOpen(false));
  }

  function updateCardsShared(newStatus: string, profileID?: number | null) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .updateCardsShared({
        ids: checkedItems,
        card: {
          status: newStatus,
          ...(profileID !== undefined
            ? {
                profile_id: profileID,
              }
            : {}),
        },
      })
      .then(() => setSuccess(MESSAGES.card.update.successMultiple))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .then(() => listCards({ newStatus, initial: true }))
      .finally(() => setIsOpen(false));
  }

  function handleDelete(cardID: number) {
    setIsLoading(true);
    setSuccess('');
    setError(false);
    adminAPI
      .deleteCard(cardID)
      .then(() => setSuccess(MESSAGES.card.delete.success(cardID)))
      .catch(() => setError(true))
      .then(() => setIsLoading(false))
      .then(() => listCards({ newPage: 1 }))
      .finally(() => setIsOpen(false));
  }

  const exportCSV = useCallback(async () => {
    if (checkedItems) {
      const response = await adminAPI.exportCardsCSV(checkedItems);
      const blob = new Blob([response.data]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'admin_cards.csv';
      a.click();
    }
  }, [checkedItems]);

  const handleBulkUpdate = useCallback(() => {
    if (importCSV) {
      setIsLoading(true);
      setSuccess('');
      setError(false);
      const formData = new FormData();
      formData.append('file', importCSV);
      formData.append('type', 'card');
      adminAPI
        .importCardsCSV(formData)
        .then(() => setSuccess(MESSAGES.card.import.success))
        .catch(() => setError(true))
        .then(() => setIsLoading(false))
        .then(() => listCards({ newPage: 1 }))
        .finally(() => setIsBulkUpdateOpen(false));
    }
  }, [importCSV, listCards]);

  const listSalesChannels = useCallback(async (): Promise<{
    data: ISalesChannel[];
  }> => {
    const res = await adminAPI.listSalesChannels();

    return res.data;
  }, []);

  const showSubscription = async (orgID: number, subscriptionId: string) => {
    const res = await saasAPI.showSubscription(orgID, subscriptionId);

    return res.data;
  };

  useEffect(() => {
    if (uOrgID && scope === 'user') {
      history.replace('/');

      const uUserRole = 'org_admin';
      const uFeatures = ['saas'];

      if (uPlatformSubscriptionStatus) {
        setScopeAndOrg(
          scope,
          Number(uOrgID),
          true,
          'premium',
          null,
          uUserRole,
          uFeatures,
          null,
          uPlatformSubscriptionStatus,
        );
      } else {
        setScopeAndOrg(
          scope,
          Number(uOrgID),
          true,
          'premium',
          null,
          uUserRole,
          uFeatures,
          null,
          SubscriptionStatus.FREE,
        );
      }
    }

    if (initialLoading) {
      tabs.map(async item => {
        await listCards({ newStatus: item.value, initial: true }).then(
          ({ paging }) => {
            if (paging) {
              setCount(item.value, paging.total_entries);
            }
          },
        );
      });
      listSalesChannels()
        .then(res => {
          if (res.data && res.data.length > 0) {
            setSalesChannels(res.data);
            setSalesChannel(String(res.data[0].id));
          }
        })
        .finally(() => {});
      setInitialLoading(false);
    }
  }, [
    initialLoading,
    listCards,
    history,
    scope,
    setScopeAndOrg,
    uOrgID,
    listSalesChannels,
    uPlatformSubscriptionStatus,
  ]);

  return (
    <Layout pageName="Cards">
      <div className="md:pb-8 md:flex md:items-center md:justify-end md:-mt-20">
        <div className="mt-3 flex space-x-4 md:mt-0 md:ml-4">
          <Modal
            isOpen={isBulkUpdateOpen}
            setIsOpen={setIsBulkUpdateOpen}
            buttonTitle="Bulk Update"
            dialogTitle={MESSAGES.card.import.heading}
            dialogDescription={MESSAGES.card.import.description}
            successButtonText="Import"
            onSuccess={() => handleBulkUpdate()}
            isLoading={isLoading}
          >
            <div className="mt-6 space-y-4">
              <div className="border rounded-md px-4 mt-6 flex flex-row items-center justify-between">
                <FileUploadButtonComponent
                  filename={importCSV?.name}
                  fileFormat=".csv"
                  fileFormatMessage="(.csv only)"
                  onFileSelected={file => setImportCSV(file)}
                  loading={isLoading}
                />
                {importCSV && (
                  <button
                    type="button"
                    onClick={() => setImportCSV(undefined)}
                    className="appearance-none text-brand-500 text-sm font-medium"
                  >
                    Remove link
                  </button>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            buttonTitle="Create cards"
            dialogTitle={MESSAGES.card.create.heading}
            successButtonText="Create cards"
            onSuccess={() => handleCreateCards()}
            isLoading={isLoading}
          >
            <div className="mt-6 space-y-4">
              <Input
                label="Number of cards"
                value={numberOfCards}
                onChange={setNumberOfCards}
                type="text"
              />
              <InputSelect
                id="card-type"
                label="Card type"
                options={cardTypes}
                value={cardType}
                onChange={setCardType}
              />
              {salesChannels && salesChannels.length > 0 && (
                <InputSelect
                  id="sales-channel"
                  label="Sales channel"
                  options={salesChannels.map(({ id, name }) => ({
                    label: name,
                    value: String(id),
                  }))}
                  value={salesChannel}
                  onChange={setSalesChannel}
                />
              )}
            </div>
          </Modal>
        </div>
      </div>
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}
      <Tab.Group
        defaultIndex={cardStatus}
        onChange={index => {
          setPage(1);
          setError(false);
          setSuccess('');
          setStatus(tabs[index].value);
          selectCardStatus(index);
          setSelectAll(false);
          setCheckedItems([]);
          selectCards([]);
          listCards({ newStatus: tabs[index].value }).finally(() => {});
        }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full md:border-b border-gray-200">
          <div className="pt-4 w-full md:w-auto">
            <TabList
              tabs={tabs.map(item => {
                return {
                  ...item,
                  count: getCount(item.value),
                };
              })}
              value={status}
            />
          </div>
        </div>
        <Tab.Panels>
          {tabs.map(tab => (
            <Tab.Panel key={tab.name} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 flex-shrink-0 sm:space-x-3 items-start sm:flex-row sm:space-y-0 sm:items-center sm:justify-end">
                  <Search
                    id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                    search={search}
                    setSearch={setSearch}
                    fetchQuery={searchQuery =>
                      listCards({ newSearch: searchQuery })
                    }
                  />
                  <Sort
                    id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                    options={[
                      {
                        sort: 'date',
                        order: 'desc',
                        label: 'Date created (Newest first)',
                      },
                      {
                        sort: 'date',
                        order: 'asc',
                        label: 'Date created (Oldest first)',
                      },
                    ]}
                    sort={sort}
                    setSort={setSort}
                    order={order}
                    setOrder={setOrder}
                    fetchQuery={(sortQuery, orderQuery) =>
                      listCards({ newSort: sortQuery, newOrder: orderQuery })
                    }
                  />
                </div>
                {data && data.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8">
                          <div className="md:hidden flex items-center px-4 py-2 sm:px-6 bg-white border-b border-gray-200">
                            <InputCheckbox
                              id="select-all"
                              label=""
                              value={selectAll}
                              onChange={value => {
                                setSelectAll(value);
                                if (value) {
                                  const arr = data.map(({ id }) => id);
                                  setCheckedItems(arr);
                                  selectCards(arr);
                                } else {
                                  setCheckedItems([]);
                                  selectCards([]);
                                }
                              }}
                            />
                          </div>
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li
                              key="CardsListHeader"
                              className="hidden md:block"
                            >
                              <div className="bg-gray-50">
                                <div className="flex items-center px-4 py-2 sm:px-6">
                                  <div className="w-7">
                                    <InputCheckbox
                                      id="select-all"
                                      label=""
                                      value={selectAll}
                                      onChange={value => {
                                        setSelectAll(value);
                                        if (value) {
                                          const arr = data.map(({ id }) => id);
                                          setCheckedItems(arr);
                                          selectCards(arr);
                                        } else {
                                          setCheckedItems([]);
                                          selectCards([]);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="min-w-0 flex-1 flex items-start md:items-center">
                                    <div
                                      className={classNames(
                                        status === 'unprogrammed' ||
                                          status === 'programmed'
                                          ? 'xl:grid-cols-7 pr-4'
                                          : '',
                                        status === 'connected'
                                          ? 'xl:grid-cols-7'
                                          : '',
                                        status === 'all'
                                          ? 'xl:grid-cols-8'
                                          : '',
                                        status === 'pending_to_print'
                                          ? 'xl:grid-cols-8'
                                          : '',
                                        'min-w-0 flex-1 md:grid md:grid-cols-6',
                                      )}
                                    >
                                      <p className="col-span-2 xl:col-span-1 text-sm font-medium text-gray-900">
                                        CARD ID
                                      </p>
                                      <p className="xl:col-span-1 hidden xl:block text-sm font-medium text-gray-900">
                                        CARD TYPE
                                      </p>
                                      <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                        CREATED
                                      </p>
                                      {status !== 'pending_to_print' &&
                                        status !== 'connected' && (
                                          <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                            ACTIVATION KEY
                                          </p>
                                        )}
                                      {status === 'all' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          CARD STATUS
                                        </p>
                                      )}
                                      {[
                                        'pending_to_print',
                                        'connected',
                                        'all',
                                      ].includes(status) && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          LINKED PROFILE
                                        </p>
                                      )}
                                      {[
                                        'pending_to_print',
                                        'connected',
                                        'all',
                                      ].includes(status) && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          ORGANISATION
                                        </p>
                                      )}
                                      {status === 'pending_to_print' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900">
                                          ORDER
                                        </p>
                                      )}
                                      {status !== 'connected' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900 text-center">
                                          URL
                                        </p>
                                      )}
                                      {status === 'connected' && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900 text-center">
                                          UNLINK PROFILE
                                        </p>
                                      )}
                                      {[
                                        CARD_STATUS.PENDING_TO_PRINT,
                                        CARD_STATUS.CONNECTED,
                                      ].includes(status) && (
                                        <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-1 text-sm font-medium text-gray-900 text-center">
                                          DELETE
                                        </p>
                                      )}
                                      <p
                                        className={classNames(
                                          'mt-2 md:mt-0 col-span-4 text-sm font-medium text-gray-900',
                                          [
                                            'pending_to_print',
                                            'connected',
                                            'all',
                                          ].includes(status)
                                            ? 'xl:col-span-1'
                                            : 'xl:col-span-2',
                                        )}
                                      />
                                    </div>
                                  </div>
                                  {(status === 'unprogrammed' ||
                                    status === 'programmed') && (
                                    <div className="w-5" />
                                  )}
                                </div>
                              </div>
                            </li>
                            {data?.map((item: ICard) => (
                              <CardsListItem
                                key={`${item.status}-${item.id}`}
                                id={item.id}
                                type={
                                  cardTypes.find(
                                    types => types.value === item.type,
                                  )?.label
                                }
                                activationKey={item.activation_key}
                                insertedAt={new Date(item.inserted_at)}
                                status={status}
                                cardStatus={
                                  tabs.find(card => card.value === item.status)
                                    ?.name
                                }
                                orderNumber={item.order_number}
                                profile={item.profile}
                                selected={checkedItems.includes(item.id)}
                                cardPageURL={item.card_page_url}
                                note={item.admin_note}
                                checkItem={() => handleCheckItem(item.id)}
                                handleProgrammed={() =>
                                  updateCard(item.id, 'programmed')
                                }
                                handleUnprogrammed={() =>
                                  updateCard(item.id, 'unprogrammed')
                                }
                                handleConnectProfile={(
                                  profileID: number | undefined,
                                  orgID: number | undefined,
                                ) =>
                                  updateCard(
                                    item.id,
                                    'connected',
                                    '',
                                    profileID,
                                    orgID,
                                  )
                                }
                                handleEditNote={(
                                  admin_note: string | undefined,
                                ) =>
                                  updateCard(item.id, 'programmed', admin_note)
                                }
                                handleUnlinkProfile={() =>
                                  updateCard(item.id, 'programmed', '', null)
                                }
                                handleDelete={() => handleDelete(item.id)}
                              />
                            ))}
                          </ul>
                          {isLoading && (
                            <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.card.list.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.card.list.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Pagination
        id={`CardsList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listCards({ newPage: pageQuery, newPageSize: pageSizeQuery })
        }
        setIsLoading={setIsLoading}
      />
      <div className="block" aria-hidden="true">
        <div className="py-8" />
      </div>
      <Modal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        dialogTitle={
          <>
            <span className="font-semibold">Warning:</span> Deleting these cards
            and profiles is permanent.
          </>
        }
        dialogDescription={
          <>
            <div>
              By proceeding, you acknowledge that you will permanently delete
              this card.
            </div>
            <div className="flex mt-8">
              <InputCheckbox
                id="delete-acknowledged"
                onChange={() => setDeleteIsAcknowledged(!deleteIsAcknowledged)}
                value={deleteIsAcknowledged}
                label="I am sure I want to delete."
                labelClassName="text-gray-700 font-medium ml-3"
              />
            </div>
          </>
        }
        successButtonText="Delete"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          setIsLoading(true);
          setSuccess('');
          setError(false);
          adminAPI
            .deleteCardsShared({
              ids: checkedItems,
            })
            .then(() => setSuccess(MESSAGES.card.delete.successMultiple))
            .catch(() => setError(true))
            .then(() => setIsLoading(false))
            .then(() => setCheckedItems([]))
            .then(() => listCards({ newPage: 1 }))
            .finally(() => setIsDeleteOpen(false));
        }}
        isLoading={isLoading}
        isDisabled={!deleteIsAcknowledged}
      />
      <InfoPanelFooter
        className={checkedItems?.length > 1 ? '' : '!-bottom-28'}
      >
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap justify-end space-x-4">
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
          {status === 'unprogrammed' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Mark as unconnected"
              onClick={() => updateCardsShared('programmed')}
            />
          )}
          {status === 'programmed' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Mark as unprogrammed"
              onClick={() => updateCardsShared('unprogrammed')}
            />
          )}
          {cardIsDeletable(status) && (
            <Button
              kind={BUTTON_KIND.RED}
              buttonText="Delete cards"
              onClick={() => setIsDeleteOpen(true)}
            />
          )}
          {status === 'connected' && (
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Unlink selected cards"
              onClick={() => updateCardsShared('programmed', null)}
            />
          )}
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export selected cards"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.card.export.success))
                .catch(() => setError(true))
                .then(() => setCheckedItems([]))
                .then(() => listCards({ newPage: 1 }))
                .finally(() => setIsLoading(false));
            }}
          />
        </div>
      </InfoPanelFooter>
    </Layout>
  );
};

export default CardsListPage;
