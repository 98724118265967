import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import Badge, { BADGE_KIND } from '@/components/Badge';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import { ILinkedActivationKey } from '@/types/IActivationKey';

import Modal from './Modals/Modal';

interface IProfileListAdminItem {
  id: number;
  firstName?: string;
  lastName?: string;
  orgID?: number;
  orgName?: string;
  type: string;
  status: string;
  cardActivation?: ILinkedActivationKey[];
  cardID?: number[];
  selected: boolean;
  checkItem: () => void;
  handleDelete: () => void;
  handleExport: () => void;
  handleMove: () => void;
  viewProfile: string;
  profilePageURL: string;
}

export default function ProfileListAdminItem({
  id,
  firstName,
  lastName,
  orgID,
  orgName,
  cardActivation,
  cardID,
  type,
  status,
  selected,
  checkItem,
  handleDelete,
  handleExport,
  handleMove,
  viewProfile,
  profilePageURL,
}: IProfileListAdminItem) {
  const history = useHistory();

  const [clipboardCopy, setClipboardCopy] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div
              className={classNames(
                'min-w-0 flex-1 md:grid md:grid-cols-4 md:gap-4 pr-4',
                type === 'unconnected' ? 'xl:grid-cols-6' : '',
                type === 'connected' ? 'xl:grid-cols-7' : '',
                type === 'all' ? 'xl:grid-cols-8' : '',
              )}
            >
              <div className="flex flex-col justify-center">
                {firstName && (
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {firstName} {lastName}
                  </p>
                )}
                <p className="text-sm text-gray-500">#{id}</p>
              </div>
              <div className="md:hidden xl:flex items-center">
                {orgID && (
                  <button
                    type="button"
                    onClick={() =>
                      history.push(`/view-organisation/${orgID}`, {
                        fromName: 'Profiles',
                        fromURL: '/profiles-list',
                      })
                    }
                    className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
                  >
                    {orgName}
                    <br />
                    <span className="text-sm md:hidden">Org ID:&nbsp;</span>
                    {`#${orgID}`}
                  </button>
                )}
              </div>
              <div className="flex items-center">
                {status && (
                  <p>
                    {status === 'active' && (
                      <Badge kind={BADGE_KIND.GREEN} text="Active" />
                    )}
                    {status === 'editing' && (
                      <Badge
                        kind={BADGE_KIND.YELLOW}
                        text="Cardholder editing"
                      />
                    )}
                    {status === 'cleared' && (
                      <Badge kind={BADGE_KIND.YELLOW} text="Cleared" />
                    )}
                    {status === 'unprinted' && (
                      <Badge kind={BADGE_KIND.RED} text="Set up required" />
                    )}
                    {status === 'deleted' && (
                      <Badge kind={BADGE_KIND.RED} text="Deleted" />
                    )}
                  </p>
                )}
              </div>
              {type !== 'unconnected' && (
                <div className="md:hidden xl:flex items-center">
                  {cardID && cardID.length > 0 && (
                    <p className="text-sm font-medium text-gray-900">
                      <span className="text-sm md:hidden">
                        Linked Card:&nbsp;
                      </span>
                      {cardID.map((item, index) => {
                        return `${index !== 0 ? ', ' : ''}#${item}`;
                      })}
                    </p>
                  )}
                </div>
              )}
              <div className="md:hidden xl:flex items-center">
                {cardActivation && cardActivation[0] && (
                  <p className="text-sm font-medium text-gray-900">
                    {/* Change after MVP - Profiles can be linked to multiple cards */}
                    <span className="text-sm md:hidden">
                      Linked Activation Key:&nbsp;
                    </span>
                    #{cardActivation[0].activation_key}
                  </p>
                )}
              </div>
              {type === 'all' && (
                <div className="md:hidden xl:flex items-center">
                  <p className="text-sm font-medium text-gray-900">
                    <span className="text-sm md:hidden">State:&nbsp;</span>
                    {cardID && cardID.length > 0 ? 'Connected' : 'Unconnected'}
                  </p>
                </div>
              )}
              <div className="mt-2 lg:mt-0 xl:col-span-2">
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 w-full h-full items-start sm:items-center xl:justify-end">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText={
                      clipboardCopy ? 'Profile URL copied' : 'Copy Profile URL'
                    }
                    onClick={() => {
                      navigator.clipboard
                        .writeText(profilePageURL)
                        .then(() => setClipboardCopy(true))
                        .finally(() =>
                          setTimeout(() => {
                            setClipboardCopy(false);
                          }, 2000),
                        );
                    }}
                  />
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="View profile"
                    href={viewProfile}
                    external
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <Menu as="div" className="relative inline-block text-left">
              <div className="flex items-center">
                <Menu.Button className="appearance-none">
                  <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={handleExport}
                        className="w-full text-left block px-4 py-2 text-sm"
                      >
                        Export profile
                      </button>
                    </Menu.Item>
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={handleMove}
                        className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                      >
                        Assign to another organisation
                      </button>
                    </Menu.Item>
                    {type === 'unconnected' && (
                      <Menu.Item>
                        <button
                          type="button"
                          onClick={() => setIsDeleteOpen(true)}
                          className="text-red-500 w-full text-left block px-4 py-2 text-sm"
                        >
                          Delete profile
                        </button>
                      </Menu.Item>
                    )}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <Modal
            isOpen={isDeleteOpen}
            setIsOpen={setIsDeleteOpen}
            dialogTitle={MESSAGES.profile.delete.heading}
            dialogDescription={MESSAGES.profile.delete.description}
            successButtonText="Delete profile"
            successButtonKind={BUTTON_KIND.RED}
            onSuccess={() => {
              handleDelete();
              setIsDeleteOpen(false);
            }}
          />
        </div>
      </div>
    </li>
  );
}
