import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Disclosure, Tab } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';

import moment from 'moment-timezone';

import adminAPI from '@/api/admin';
import ordersAPI from '@/api/ordersV2';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import DesignBar from '@/components/DesignBar';
import InfoPanelFooter from '@/components/InfoPanelFooter';
import InputCheckbox from '@/components/InputCheckbox';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import OrderDetails from '@/components/OrdersPage/OrderDetails/OrderDetails';
import OrdersListItem from '@/components/OrdersPage/OrdersV2ListAdmin/Item';
import TableHeader from '@/components/OrdersPage/shared/TableHeader';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import STAGE from '@/constants/stage';
import classNames from '@/helpers/classNames';
import useAuth from '@/hooks/useAuth';
import useDialog from '@/hooks/useDialog';
import orderTableHeader from '@/pages/OrderV2Page/OrdersV2ListAdmin/TableHeader';
import { IListNew, IListPaging } from '@/types/IList';
import IOrder from '@/types/IOrderV2';

import { CustomScroll } from '../../../styleds/CustomScroll';
import OrderTabList from './Tablist';
import OrderConstrain from './constrains/constrain';

export default function OrdersListPage({
  orderDetailUUID,
}: {
  orderDetailUUID: string | undefined;
}): JSX.Element {
  const { openDialog, loadingDialog, closeDialog } = useDialog();
  const { user } = useAuth();
  const { hash } = useLocation();

  const [orders, setOrders] = useState<IOrder[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const getTabs = useCallback(() => {
    const allowOrderNewFeature =
      import.meta.env.VITE_ALLOW_ORDER_NEW_FEATURE === 'true';

    return [
      {
        name: STAGE.order_stage_name.new,
        value: STAGE.order.new,
      },
      {
        name: STAGE.order_stage_name.awaiting_client,
        value: STAGE.order.awaiting_client,
      },
      {
        name: STAGE.order_stage_name.design_edits,
        value: STAGE.order.design_edits,
      },
      {
        name: STAGE.order_stage_name.ready_for_print,
        value: STAGE.order.ready_for_print,
      },
      ...(allowOrderNewFeature
        ? [
            {
              name: STAGE.order_stage_name.in_print,
              value: STAGE.order.in_print,
            },
          ]
        : []),
      {
        name: STAGE.order_stage_name.fulfilled,
        value: STAGE.order.fulfilled,
      },
      {
        name: STAGE.order_stage_name.all_orders,
        value: STAGE.order.all_orders,
      },
      {
        name: STAGE.order_stage_name.on_hold,
        value: STAGE.order.on_hold,
      },
    ];
  }, [user]);

  const defaultTab =
    getTabs().find(tab => tab.value === hash.slice(1)) || getTabs()[0];

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(defaultTab.value);
  const [sort, setSort] = useState(status === 'new' ? 'tag' : 'updated_at');
  const [order, setOrder] = useState('desc');
  const [selectAll, setSelectAll] = useState(false);
  const [initial, setInitial] = useState(false);
  const [onDragTab, setOnDragTab] = useState<string>();
  const [orderDetailData, setOrderDetailData] = useState<IOrder>();
  const [isOpenOrderDetailModalByUUID, setIsOpenOrderDetailModalByUUID] =
    useState(false);
  const [isMoveOrder, setIsMoveOrder] = useState(false);

  useEffect(() => {
    orderDetailUUID &&
      ordersAPI.showOrder(orderDetailUUID).then(res => {
        setOrderDetailData(res.data.data);
        setIsOpenOrderDetailModalByUUID(true);
      });
  }, [orderDetailUUID]);

  function handleCheckedItem(orderId: string) {
    let arr = checkedItems;
    if (checkedItems.includes(orderId)) {
      arr = arr.filter(e => e !== orderId);
    } else {
      arr = [...checkedItems, orderId];
    }
    setCheckedItems(arr);
  }

  function handleTabColor(status: string) {
    switch (status) {
      case STAGE.order.new:
        return 'red';
      case STAGE.order.awaiting_client:
        return 'orange';
      case STAGE.order.design_edits:
        return 'yellow';
      case STAGE.order.ready_for_print:
        return 'green';
      case STAGE.order.in_print:
        return 'in_print';
      case STAGE.order.fulfilled:
        return 'fullfiled';
      case STAGE.order.all_orders:
        return 'gray';
      case STAGE.order.on_hold:
        return 'gray';
      default:
        return '';
    }
  }

  const listOrders = useCallback(
    async ({
      newPage = page,
      newPageSize = pageSize,
      newSort = sort,
      newOrder = order,
      newStatus = status,
      newSearch = search,
      initial = false,
    }: IListNew): Promise<{ data: IOrder[]; paging: IListPaging }> => {
      const res = await ordersAPI.listOrders({
        page: newPage,
        pageSize: newPageSize,
        sort: newSort,
        order: newOrder,
        status: newStatus === 'new' ? 'new,design_needed' : newStatus,
        search: newSearch,
      });

      setOrders(res.data?.data);
      setPage(newPage);
      setPageSize(newPageSize);
      setStatus(newStatus);
      setSearch(newSearch);
      setIsLoading(false);

      return res.data;
    },
    [page, pageSize, sort, order, status, search],
  );

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    orderId: number,
  ): void => {
    e.dataTransfer.setData('order_id', String(orderId));
  };

  const exportCSV = useCallback(
    async (arr?: string[]) => {
      if (arr || checkedItems) {
        const response = await adminAPI.exportProfilesViaOrderCSV(
          arr || checkedItems,
        );

        const blob = new Blob([response.data]);
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'admin_profiles.csv';
        a.click();
      }
    },
    [checkedItems],
  );

  const { isLoading: isDeletingOrders, mutate: deleteAllOrders } = useMutation(
    () => ordersAPI.deleteOrders(checkedItems),
    {
      onSuccess: res => {
        listOrders({ initial: true });
        setSuccess('Cart(s) were deleted successfully');
        closeDialog();
        setCheckedItems([]);
      },
      onError: err => {
        setError(true);
        closeDialog();
      },
    },
  );

  useEffect(() => {
    loadingDialog(isDeletingOrders);
    return () => {};
  }, [isDeletingOrders, loadingDialog]);

  const handleDeleteOrdes = async () => {
    deleteAllOrders();
  };

  return (
    <Layout
      pageName="Shopify Orders"
      wrapClassName="flex flex-col"
      rightTitle={
        status === 'in_print' && (
          <div className="rounded-md bg-brand-100 text-white p-3 md:mt-0 absolute right-0 min-w-[32rem]">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between text-sm font-medium text-left w-full">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-gray-900"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-gray-900">
                          {MESSAGES.shopify.info.heading}
                        </h3>
                        <Disclosure.Panel className="flex flex-wrap gap-2">
                          <p className="mt-2 text-sm leading-5 text-gray-600 font-normal">
                            {MESSAGES.shopify.info.description}
                          </p>
                        </Disclosure.Panel>
                      </div>
                    </div>

                    <ChevronDownIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-brand-500`}
                    />
                  </Disclosure.Button>
                </>
              )}
            </Disclosure>
          </div>
        )
      }
    >
      {success !== '' && <SuccessAlert message={success} />}
      {error && <ErrorAlert message={MESSAGES.error.generic} />}

      <Tab.Group
        onChange={(index: number) => {
          setPage(1);
          setError(false);
          setStatus(getTabs()[index].value);
          setSelectAll(false);
        }}
        selectedIndex={getTabs().findIndex(tab => tab.value === status)}
      >
        <OrderTabList
          handleOrderStatusChange={() => {
            listOrders({ initial: true });
            setInitial(!initial);
          }}
          status={status}
          setOnDragTab={setOnDragTab}
          onDragTab={onDragTab}
          initial={initial}
          tabs={getTabs()}
        />
        <Tab.Panels>
          {getTabs().map((tab, index) => (
            <Tab.Panel key={index} className="outline-none">
              <div className="py-8">
                <div className="pb-8 flex flex-col space-y-3 items-start sm:flex-row-reverse sm:space-y-0 sm:items-center sm:justify-between">
                  <DesignBar />
                  <div className="flex flex-shrink-0 space-x-3">
                    <div className="inline-flex">
                      <div className="relative mr-4">
                        <Search
                          id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                          placeholder="Search"
                          search={search}
                          setSearch={setSearch}
                          fetchQuery={newSearch => {
                            return listOrders({ newSearch });
                          }}
                        />
                      </div>
                      <Sort
                        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
                        options={OrderConstrain.sortOptions}
                        sort={sort}
                        setSort={setSort}
                        order={order}
                        setOrder={setOrder}
                        fetchQuery={(newSort, newOrder) =>
                          listOrders({ newSort, newOrder })
                        }
                      />
                    </div>
                  </div>
                </div>
                {orders && orders.length > 0 ? (
                  <div className="flex flex-col">
                    <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                      <div className="block w-full lg:px-8">
                        <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 ">
                          <ul
                            className={classNames(
                              isLoading ? 'opacity-40' : '',
                              'divide-y divide-gray-200',
                            )}
                          >
                            <li key="OrderListHeader">
                              <div className="bg-gray-50">
                                <table
                                  className="w-full text-left text-gray-500 dark:text-gray-400"
                                  style={{ tableLayout: 'fixed' }}
                                >
                                  <thead className="bg-gray-50">
                                    <tr className=" uppercase">
                                      <th scope="col " className="w-16">
                                        <InputCheckbox
                                          id="select-all"
                                          label=""
                                          value={selectAll}
                                          onChange={value => {
                                            setSelectAll(value);
                                            if (value) {
                                              const arr = orders.map(
                                                ({ order_number }) =>
                                                  order_number,
                                              );
                                              setCheckedItems(arr);
                                            } else {
                                              setCheckedItems([]);
                                            }
                                          }}
                                        />
                                      </th>
                                      {orderTableHeader(status).map(
                                        (item, index) => {
                                          return (
                                            <TableHeader
                                              key={index}
                                              style={item.style}
                                              className={item.className}
                                              scope={item.scope}
                                            >
                                              {item.children}
                                            </TableHeader>
                                          );
                                        },
                                      )}
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </li>

                            <CustomScroll
                              style={{
                                height: 'calc(100vh - 408px)',
                                overflowY: 'scroll',
                              }}
                            >
                              {orders.map(order => (
                                <OrdersListItem
                                  order={order}
                                  key={order.id}
                                  id={order.id}
                                  orderUrl={order.order_url}
                                  uuid={order.uuid}
                                  customer_name={order.customer_name}
                                  customer_email={order.customer_email}
                                  design_specs={order.design_specs}
                                  selected={checkedItems.includes(
                                    order.order_number,
                                  )}
                                  handleCheckedItem={() =>
                                    handleCheckedItem(order.order_number)
                                  }
                                  handleTabColor={() =>
                                    handleTabColor(order.order_stage)
                                  }
                                  orderType={order.order_origin}
                                  orderNumber={order.order_number}
                                  numberOfProfiles={order.order_quantity}
                                  orderStatus={status}
                                  url={order.design_file?.original_url}
                                  orgId={order.organisation_id}
                                  notifications_sent={order.notifications_sent}
                                  designFile={order.design_file}
                                  alternate_email={order.alternate_email}
                                  alternate_email_flag={
                                    order.alternate_email_flag
                                  }
                                  activationId={undefined}
                                  has_qr_codes={
                                    order.has_generated_cards &&
                                    order.order_quantity > 0
                                  }
                                  handleOrderStatusChange={() => {
                                    listOrders({ initial: true });
                                    setInitial(!initial);
                                  }}
                                  submission_date={moment(
                                    String(order.updated_at),
                                  ).format('DD/MM/YY')}
                                  is_priority={order.is_priority}
                                  setSuccess={setSuccess}
                                  setError={setError}
                                  onDragStart={handleDragStart}
                                  tag={order.tag || 'on_hold'}
                                  paused_by={order.paused_by}
                                  shipping_country={order.shipping_country}
                                  inserted_at={moment(
                                    String(order.inserted_at),
                                  ).format('DD/MM/YY')}
                                />
                              ))}
                            </CustomScroll>
                          </ul>
                          {isLoading && (
                            <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                              <LoadingAnimation className="text-brand-500 w-16 h-16 mx-auto" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="py-32">
                    <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                      {MESSAGES.order.empty.heading}
                    </h3>
                    <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                      {MESSAGES.order.empty.description}
                    </p>
                  </div>
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <Pagination
        id={`OrdersList-${page}-${pageSize}-${sort}-${order}-${status}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(newPage, newPageSize) =>
          listOrders({ newPage, newPageSize })
        }
        setIsLoading={setIsLoading}
      />

      {/* Export selected profile */}

      <InfoPanelFooter className={checkedItems.length > 0 ? '' : '!-bottom-28'}>
        <p className="text-sm leading-5 text-gray-500 mb-1 sm:hidden">{`${checkedItems.length} selected`}</p>
        <div className="flex items-center flex-nowrap justify-end space-x-4">
          <p className="text-sm leading-5 text-gray-500 hidden sm:block">{`${checkedItems.length} selected`}</p>
          <Button
            kind={BUTTON_KIND.WHITE}
            buttonText="Export profiles from selected orders"
            onClick={() => {
              setIsLoading(true);
              setSuccess('');
              setError(false);
              exportCSV()
                .then(() => setSuccess(MESSAGES.profile.export.success))
                .catch(() => setError(true))
                .then(() => setIsLoading(false))
                .finally(() => setCheckedItems([]));
            }}
          />
          <Button
            kind={BUTTON_KIND.LIGHT_RED}
            buttonText="Delete cards"
            onClick={() => {
              openDialog({
                isOpen: true,
                dialogTitle: MESSAGES.order.delete.heading,
                dialogDescription: MESSAGES.order.delete.description,
                successButtonText: 'Delete cards',
                onSuccess: () => {
                  handleDeleteOrdes();
                },
              });
            }}
          />
        </div>
      </InfoPanelFooter>

      {orderDetailData && (
        <OrderDetails
          order={orderDetailData}
          orderId={orderDetailData.id}
          orgId={orderDetailData.organisation_id}
          orderStage={orderDetailData.order_origin}
          isOpenOrderDetailModal={isOpenOrderDetailModalByUUID}
          setIsOpenOrderDetailModal={setIsOpenOrderDetailModalByUUID}
          setIsMoveOrder={setIsMoveOrder}
          orderNumber={orderDetailData.order_number}
          design_specs={false}
          isPriority={orderDetailData.is_priority}
          handleClickLinkedOrg={(orgId: number) => {
            if (!orgId) {
              setError(true);
              return;
            }
            window.open(`/?scope=user&uOrgID=${orgId}`);
          }}
          orderAlternateEmail={orderDetailData.alternate_email}
          orderAlternateEmailFlag={orderDetailData.alternate_email_flag}
          handleOrderStatusChange={() => {
            listOrders({ initial: true });
            setInitial(!initial);
          }}
          tag={orderDetailData.tag}
          customerName={orderDetailData.customer_name}
          customerEmail={orderDetailData.customer_email}
          orderStatusUrl={orderDetailData.order_status_url}
        />
      )}
    </Layout>
  );
}
