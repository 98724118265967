import React from 'react';

import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';

import { ErrorAlert } from '@/components/Alert';
import Input from '@/components/Input';
import UploadPhoto from '@/components/UploadPhoto';
import { PROFILE_IMAGE } from '@/constants/files';
import MESSAGES from '@/constants/messages-en';
import IFile from '@/types/IFile';

interface IPersonalInfo {
  photo?: IFile;
  firstName: string;
  firstNameError?: boolean;
  middleName: string;
  lastName: string;
  lastNameError?: boolean;
  pronouns: string;
  pronounsError?: boolean;
  email: string;
  extraEmails: string[];
  emailError?: boolean;
  mobileNumber: string;
  extraMobileNumbers: string[];
  mobileNumberError?: boolean;
  jobTitle: string;
  jobTitleError?: boolean;
  note: string;
  disabled?: boolean;
  setPhoto: React.Dispatch<React.SetStateAction<IFile | undefined>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setMiddleName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setPronouns: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setExtraEmails: React.Dispatch<React.SetStateAction<string[]>>;
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>;
  setExtraMobileNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  setJobTitle: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
}

type ExtraEmailProps = {
  email: string;
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  disabled: boolean;
};

const ExtraEmail: React.FC<ExtraEmailProps> = ({
  email,
  emails,
  setEmails,
  index,
  disabled,
}) => {
  return (
    <div key={index} className="mt-2">
      <Input
        label="Email address"
        type="email"
        value={email}
        required
        onChange={value => {
          setEmails(Object.assign([...emails], { [index]: value }));
        }}
        disabled={disabled}
        copy={disabled}
        placeholder="Enter email address"
        rightIcon={
          <MinusCircleIcon className="text-red-450 z-20 h-5 w-5 cursor-pointer" />
        }
        handleRightIconClick={() => {
          setEmails(emails.filter((_, i) => i !== index));
        }}
      />
    </div>
  );
};

type ExtraMobileNumberProps = {
  mobileNumber: string;
  mobileNumbers: string[];
  setMobileNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  disabled: boolean;
};

const ExtraMobileNumber: React.FC<ExtraMobileNumberProps> = ({
  mobileNumber,
  mobileNumbers,
  setMobileNumbers,
  index,
  disabled,
}) => {
  return (
    <div key={index} className="mt-2">
      <Input
        label="Mobile number"
        type="text"
        value={mobileNumber}
        onChange={value => {
          setMobileNumbers(
            Object.assign([...mobileNumbers], { [index]: value }),
          );
        }}
        disabled={disabled}
        copy={disabled}
        placeholder="Enter mobile number"
        rightIcon={
          <MinusCircleIcon className="text-red-450 z-20 h-5 w-5 cursor-pointer" />
        }
        handleRightIconClick={() => {
          setMobileNumbers(mobileNumbers.filter((_, i) => i !== index));
        }}
      />
    </div>
  );
};

export default function PersonalInfoForm({
  photo,
  firstName,
  firstNameError,
  middleName,
  lastName,
  lastNameError,
  pronouns,
  pronounsError,
  email,
  extraEmails,
  emailError,
  mobileNumber,
  mobileNumberError,
  extraMobileNumbers,
  jobTitle,
  jobTitleError,
  note,
  disabled = false,
  setPhoto,
  setFirstName,
  setMiddleName,
  setLastName,
  setPronouns,
  setEmail,
  setExtraEmails,
  setMobileNumber,
  setExtraMobileNumbers,
  setJobTitle,
  setNote,
}: IPersonalInfo) {
  return (
    <>
      {emailError && <ErrorAlert message={MESSAGES.error.email} />}
      {firstNameError && <ErrorAlert message={MESSAGES.error.firstName} />}
      {lastNameError && <ErrorAlert message={MESSAGES.error.lastName} />}
      {jobTitleError && <ErrorAlert message={MESSAGES.error.jobTitle} />}
      {pronounsError && <ErrorAlert message="Pronouns error" />}
      {mobileNumberError && (
        <ErrorAlert message={MESSAGES.error.mobileNumber} />
      )}
      <div className="flex items-center">
        <UploadPhoto
          photo={photo}
          setPhoto={setPhoto}
          disabled={disabled}
          maxHeight={PROFILE_IMAGE.MAX_HEIGHT}
          maxWidth={PROFILE_IMAGE.MAX_WIDTH}
        />
      </div>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="First name"
            type="text"
            value={firstName}
            required
            onChange={setFirstName}
            error={firstNameError}
            disabled={disabled}
            copy={disabled}
          />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Middle name"
            type="text"
            value={middleName}
            onChange={setMiddleName}
            disabled={disabled}
          />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Last name"
            type="text"
            value={lastName}
            required
            onChange={setLastName}
            error={lastNameError}
            disabled={disabled}
            copy={disabled}
          />
        </div>

        <div className="col-span-6 sm:col-span-4">
          <Input
            label="Job title / Position"
            type="text"
            value={jobTitle}
            onChange={setJobTitle}
            error={jobTitleError}
            disabled={disabled}
            copy={disabled}
          />
        </div>

        <div className="col-span-6 sm:col-span-2">
          <Input
            id="pronouns"
            type="text"
            label="Pronouns"
            onChange={setPronouns}
            value={pronouns}
            maxLength={12}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input
            label={
              extraEmails.length > 0 ? 'Primary email address' : 'Email address'
            }
            type="email"
            value={email}
            required
            onChange={setEmail}
            error={emailError}
            disabled={disabled}
            copy={disabled}
            placeholder="Enter work email address"
            rightIcon={
              <PlusCircleIcon className="text-brand-500 z-20 h-5 w-5 cursor-pointer" />
            }
            handleRightIconClick={() => {
              setExtraEmails([...extraEmails, '']);
            }}
          />
          {extraEmails.map((extraEmail, index) => (
            <ExtraEmail
              email={extraEmail}
              emails={extraEmails}
              setEmails={setExtraEmails}
              index={index}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Input
            label={
              extraMobileNumbers.length > 0
                ? 'Primary mobile number'
                : 'Mobile number'
            }
            type="text"
            value={mobileNumber}
            onChange={setMobileNumber}
            error={mobileNumberError}
            disabled={disabled}
            copy={disabled}
            placeholder="Enter work mobile number"
            rightIcon={
              <PlusCircleIcon className="text-brand-500 z-20 h-5 w-5 cursor-pointer" />
            }
            handleRightIconClick={() => {
              setExtraMobileNumbers([...extraMobileNumbers, '']);
            }}
          />
          {extraMobileNumbers.map((extraMobileNumber, index) => (
            <ExtraMobileNumber
              mobileNumber={extraMobileNumber}
              mobileNumbers={extraMobileNumbers}
              setMobileNumbers={setExtraMobileNumbers}
              index={index}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="col-span-6 sm:col-span-4">
          <label
            htmlFor="note"
            className="block text-sm font-medium text-gray-700"
          >
            Profile note
            <div className="mt-1">
              <textarea
                id="note"
                name="note"
                rows={3}
                className="shadow-sm focus:ring-brand-500 focus:border-brand-500 mt-1 block w-full text-base leading-6 border border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
                value={note}
                onChange={e => setNote(e.target.value)}
                disabled={disabled}
              />
            </div>
          </label>
        </div>
      </div>
    </>
  );
}
