import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import clsx from 'clsx';

import profilesAPI from '@/api/profiles';
import { BUTTON_KIND } from '@/components/Button';
import LoadingAnimation from '@/components/LoadingAnimation';
import ModalFullWidth from '@/components/Modals/ModalFullWidth';
import {
  ProfileListGroupItem,
  ProfileListGroupItemMobile,
} from '@/components/ProfileListGroupItem';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';

import InputCheckbox from '../InputCheckbox';
import Pagination from '../Pagination';

type RemoveProfilesModalProps = {
  groupID: number | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (profileIDs: Set<number>) => void;
};

export function RemoveProfilesModal({
  groupID,
  isOpen,
  setIsOpen,
  onSuccess,
}: RemoveProfilesModalProps) {
  const { orgID } = useAuth();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);

  const { data: profiles } = useQuery(
    ['listProfilesInGroup', page, pageSize, sort, order, search, isOpen],
    () => {
      return listProfilesInGroup(page, pageSize, sort, order, search);
    },
    {
      enabled: orgID !== undefined && groupID !== undefined,
    },
  );

  useEffect(() => {
    setCheckedItems(new Set());
  }, [sort]);

  useEffect(() => {
    if (profiles) {
      setIsAllSelected(checkedItems.size === profiles.data.length);
    }
  }, [checkedItems]);

  async function listProfilesInGroup(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
  ) {
    const res = await profilesAPI.listProfilesInGroup({
      orgID,
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
      groupID,
    });

    return res.data;
  }

  function onCheckItem(profileID: number) {
    setCheckedItems(prev => {
      const next = new Set(prev);
      if (prev.has(profileID)) {
        next.delete(profileID);
      } else {
        next.add(profileID);
      }
      return next;
    });
  }

  return (
    <ModalFullWidth
      dialogTitle="Remove profiles from group"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      successButtonText="Remove from group"
      successButtonKind={BUTTON_KIND.LIGHT_RED}
      onSuccess={() => {
        setIsOpen(false);
        onSuccess(checkedItems);
      }}
      footerText={`${checkedItems.size} selected`}
    >
      <div className="flex flex-row justify-end flex-shrink-0 space-x-3 items-start py-6">
        <div className="flex flex-shrink-0 space-x-3">
          <Search
            id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listProfilesInGroup(page, pageSize, sort, order, searchQuery)
            }
          />
          <Sort
            id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Newest first',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Oldest first',
              },
              {
                sort: 'first_name',
                order: 'asc',
                label: 'Name (A-Z)',
              },
              {
                sort: 'first_name',
                order: 'desc',
                label: 'Name (Z-A)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listProfilesInGroup(page, pageSize, sortQuery, orderQuery, search)
            }
          />
        </div>
      </div>
      {profiles ? (
        profiles.data.length > 0 ? (
          <div className="flex flex-col relative gap-6">
            <div className="hidden xl:block shadow-sm border border-gray-200 min-h-8 max-h-[450px] overflow-y-scroll overflow-hidden hide-scrollbar">
              <table
                className={clsx('w-full', {
                  'opacity-40': isPaginationLoading,
                })}
              >
                <thead
                  className="bg-gray-100 border-b border-gray-200 sticky top-0"
                  style={{ zIndex: 2 }}
                >
                  <tr className="uppercase text-gray-900 text-sm">
                    <th scope="col" className="py-3 pl-6">
                      <InputCheckbox
                        id="add-profiles-modal"
                        value={isAllSelected}
                        onChange={selected => {
                          setIsAllSelected(selected);
                          if (selected) {
                            setCheckedItems(
                              new Set(profiles.data.map(({ id }) => id)),
                            );
                          } else {
                            setCheckedItems(new Set());
                          }
                        }}
                      />
                    </th>
                    <th scope="col" className="font-medium py-3 px-6 text-left">
                      Name &amp; profile id
                    </th>
                    <th scope="col" className="font-medium py-3 px-6 text-left">
                      Contact information
                    </th>
                    <th scope="col" className="font-medium py-3 px-6 text-left">
                      Group
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {profiles.data.map(profile => (
                    <ProfileListGroupItem
                      key={profile.id}
                      profile={profile}
                      selected={checkedItems.has(profile.id)}
                      checkItem={() => onCheckItem(profile.id)}
                    />
                  ))}
                </tbody>
              </table>
              {isPaginationLoading && (
                <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                </div>
              )}
            </div>
            <div className="xl:hidden">
              <div className="bg-gray-100 border border-gray-300 rounded-md px-6 flex flex-row font-medium uppercase divide-x divide-gray-300">
                <InputCheckbox
                  id="select-all-add-profiles-modal"
                  label=""
                  value={isAllSelected}
                  onChange={selected => {
                    setIsAllSelected(selected);
                    if (selected) {
                      setCheckedItems(
                        new Set(profiles.data.map(({ id }) => id)),
                      );
                    } else {
                      setCheckedItems(new Set());
                    }
                  }}
                />
                <span className="pl-2 py-3">
                  Name, profile id, contact information, &amp; group
                </span>
              </div>
              <div className="max-h-[calc(100vh-50vh)] overflow-y-scroll overflow-hidden">
                {profiles.data.map((profile, index) => (
                  <ProfileListGroupItemMobile
                    key={index}
                    profile={profile}
                    selected={checkedItems.has(profile.id)}
                    checkItem={() => onCheckItem(profile.id)}
                  />
                ))}
              </div>
            </div>
            <Pagination
              id={`ProfileList-${page}-${pageSize}-${sort}-${order}-${search}`}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              setIsLoading={setIsPaginationLoading}
              fetchQuery={(pageQuery, pageSizeQuery) =>
                listProfilesInGroup(
                  pageQuery,
                  pageSizeQuery,
                  sort,
                  order,
                  search,
                )
              }
            />
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.profile.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.profile.list.empty.description}
            </p>
          </div>
        )
      ) : (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
        </div>
      )}
    </ModalFullWidth>
  );
}
