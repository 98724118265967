import { Link } from 'react-router-dom';

import { cx } from '@emotion/css';

type Props = {
  activeTab: 'profiles' | 'groups';
};

function ProfilesGroupsPseudoTabs({ activeTab }: Props) {
  return (
    <div className="border-b border-b-gray-200 grid gap-8 grid-flow-col justify-start">
      <Link
        className={cx(
          'text-gray-500 text-lg font-normal pb-4 hover:text-gray-900',
          {
            'text-gray-900 border-b-2 border-b-gray-900':
              activeTab === 'profiles',
          },
        )}
        to="/"
      >
        All Profiles
      </Link>
      <Link
        className={cx(
          'text-gray-500 text-lg font-normal pb-4 hover:text-gray-900',
          {
            'text-gray-900 border-b-2 border-b-gray-900':
              activeTab === 'groups',
          },
        )}
        to="/groups"
      >
        Groups
      </Link>
    </div>
  );
}

export default ProfilesGroupsPseudoTabs;
