import { useCallback, useState } from 'react';

import adminAPI from '@/api/admin';
import Button, { BUTTON_KIND } from '@/components/Button';
import ContactsListItem from '@/components/ContactsListItem';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import IContacts from '@/types/IContacts';

export default function ContactListAdminPage(): JSX.Element {
  const [data, setData] = useState<IContacts[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');

  async function listContacts(
    newPage: number = page,
    newPageSize: number = pageSize,
    newSort: string = sort,
    newOrder: string = order,
    newSearch: string = search,
  ) {
    const res = await adminAPI.listContacts({
      page: newPage,
      pageSize: newPageSize,
      sort: newSort,
      order: newOrder,
      search: newSearch,
    });

    setData(res.data.data);
    setPage(newPage);
    setPageSize(newPageSize);
    setSort(newSort);
    setOrder(newOrder);

    return res.data;
  }

  const exportCSV = useCallback(async () => {
    const response = await adminAPI.exportContactsCSV();
    const blob = new Blob([response.data]);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'admin_contacts.csv';
    a.click();
  }, []);

  return (
    <Layout pageName="Contacts">
      <div className="pb-8 pt-8 md:pt-0">
        <div className="pb-8 flex flex-col space-y-3 items-start lg:flex-row lg:space-y-0 lg:space-x-8 lg:items-end lg:justify-end">
          <div className="flex flex-shrink-0 space-y-3 flex-col lg:items-end">
            <div className="flex flex-shrink-0 space-x-3">
              <Search
                id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}`}
                search={search}
                setSearch={setSearch}
                fetchQuery={searchQuery =>
                  listContacts(page, pageSize, sort, order, searchQuery)
                }
              />
              <Sort
                id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}`}
                options={[
                  {
                    sort: 'date',
                    order: 'desc',
                    label: 'Date created (Newest first)',
                  },
                  {
                    sort: 'date',
                    order: 'asc',
                    label: 'Date created (Oldest first)',
                  },
                  {
                    sort: 'first_name',
                    order: 'asc',
                    label: 'Name (A-Z)',
                  },
                  {
                    sort: 'first_name',
                    order: 'desc',
                    label: 'Name (Z-A)',
                  },
                ]}
                sort={sort}
                setSort={setSort}
                order={order}
                setOrder={setOrder}
                fetchQuery={(sortQuery, orderQuery) =>
                  listContacts(page, pageSize, sortQuery, orderQuery, search)
                }
              />
              <Button
                kind={BUTTON_KIND.WHITE}
                buttonText="Export via CSV"
                onClick={exportCSV}
              />
            </div>
          </div>
        </div>
        {data && data.length > 0 ? (
          <div className="flex flex-col">
            <div className="relative -my-2 lg:-mx-8">
              <div className="py-2 block w-full lg:px-8">
                <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                  <ul
                    className={classNames(
                      isLoading ? 'opacity-40' : '',
                      'divide-y divide-gray-200',
                    )}
                  >
                    <li key="ContactsListHeader" className="hidden md:block">
                      <div className="bg-gray-50">
                        <div className="flex items-center px-4 py-2 sm:px-6">
                          <div className="min-w-0 flex-1 flex items-start md:items-center">
                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4">
                              <p className="md:col-span-2 text-sm font-medium text-gray-900">
                                NAME &amp; EMAIL
                              </p>
                              <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-2 text-sm font-medium text-gray-900">
                                POSITION &amp; COMPANY
                              </p>
                              <p className="mt-2 md:mt-0 text-sm font-medium text-gray-900">
                                MOBILE
                              </p>
                              <p className="mt-2 md:mt-0 text-sm font-medium text-gray-900">
                                CONNECTION
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {data?.map((contact, index) => (
                      <ContactsListItem key={index} contact={contact} />
                    ))}
                  </ul>
                  {isLoading && (
                    <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-32">
            <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
              {MESSAGES.contact.list.empty.heading}
            </h3>
            <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
              {MESSAGES.contact.list.empty.description}{' '}
              <a
                href="https://tapt.io/pages/how-to-use"
                rel="noreferrer"
                target="_blank"
              >
                Learn more.
              </a>
            </p>
          </div>
        )}
      </div>
      <Pagination
        id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listContacts(pageQuery, pageSizeQuery, sort, order, search)
        }
        setIsLoading={setIsLoading}
      />
    </Layout>
  );
}
