import { useState } from 'react';

import profilesAPI from '@/api/profiles';
import InputInline from '@/components/InputInline';
import Modal from '@/components/Modals/Modal';
import { downloadBlob, parseContentDisposition } from '@/helpers/file';
import useAuth from '@/hooks/useAuth';

type QRCodeFileSelectionModalProps = {
  checkedProfiles: number[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessCallback?: () => void;
};

const fileFormats = ['svg', 'png', 'jpg'] as const;
export type SupportedQRCodeFormat = (typeof fileFormats)[number];

export function QRCodeFileSelectionModal({
  checkedProfiles,
  isOpen,
  setIsOpen,
  onSuccessCallback,
}: QRCodeFileSelectionModalProps) {
  const { orgID } = useAuth();

  const [fileType, setFileType] = useState<SupportedQRCodeFormat | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(false);

  const allowedFileTypes = fileFormats.map(format => ({
    title: format.toUpperCase(),
    value: format,
  }));

  async function downloadQRCodeBulk() {
    if (orgID === undefined || fileType === undefined) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await profilesAPI.downloadQRCodeBulk(orgID, {
        ids: checkedProfiles,
        fileType,
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const filename = parseContentDisposition(
        response.headers['content-disposition'],
        'profile_qr_codes',
      );
      downloadBlob(blob, filename);

      onSuccessCallback?.();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadQRCode() {
    if (orgID === undefined || fileType === undefined) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await profilesAPI.downloadQRCode(
        orgID,
        checkedProfiles[0],
        fileType,
      );

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const filename = parseContentDisposition(
        response.headers['content-disposition'],
        'qr_codes',
      );
      downloadBlob(blob, filename);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Export QR Code"
      dialogDescription="Choose the format you wish to download the QR codes in."
      successButtonText="Download"
      onSuccess={() => {
        checkedProfiles.length > 1 ? downloadQRCodeBulk() : downloadQRCode();
        setFileType(undefined);
        setIsOpen(false);
      }}
      onCancel={() => {
        setFileType(undefined);
        setIsOpen(false);
      }}
      isLoading={isLoading}
      isDisabled={fileType === undefined}
    >
      <div className="flex flex-row pt-4 space-x-3">
        <InputInline
          type="radio"
          data={fileType}
          setData={setFileType}
          setting={allowedFileTypes}
          labelClassName="!font-normal text-gray-500"
        />
      </div>
    </Modal>
  );
}
