import jwtDecode, { JwtPayload } from 'jwt-decode';

export function isValidJWT(token: string): boolean {
  try {
    const jwtParts = token.split('.');
    if (jwtParts.length !== 3 || !jwtParts.every(part => part.trim() !== '')) {
      return false;
    }

    const decoded = jwtDecode<JwtPayload>(token);
    if (!decoded.exp) {
      return false;
    }

    const now = Math.round(new Date().getTime() / 1000);
    return decoded.exp > now;
  } catch (exception) {
    return false;
  }
}
