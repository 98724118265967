import { useState } from 'react';

import accountsAPI from '@/api/accounts';
import { ErrorAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import Input from '@/components/Input';

interface SsoLoginProps {
  setShowSsoLogin: (show: boolean) => void;
}

export default function SsoLogin({ setShowSsoLogin }: SsoLoginProps) {
  const [email, setEmail] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  function onLogin() {
    setLoading(true);
    accountsAPI
      .getSsoAuthUrl(email)
      .then(res => {
        if (typeof window !== 'undefined' && window.location) {
          window.location.href = res.data.url;
        }
      })
      .catch(() => setLoginError(true))
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div className="hidden lg:block bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
        {loginError && (
          <ErrorAlert message="This email is not associated with an organization configured for SSO on Tapt" />
        )}

        <div className="space-y-6">
          <Input
            label="Work email"
            type="email"
            value={email}
            onChange={setEmail}
          />

          <div>
            <Button
              buttonText="Single Sign-On"
              onClick={onLogin}
              fullWidth
              loading={loading}
            />
          </div>
          <div>
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Cancel"
              onClick={() => setShowSsoLogin(false)}
              fullWidth
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className="lg:hidden bg-white py-2 px-4 sm:rounded-lg sm:px-10">
        {loginError && (
          <ErrorAlert message="This email is not associated with an organization configured for SSO on Tapt" />
        )}

        <div className="space-y-6">
          <Input
            label="Work email"
            type="email"
            value={email}
            onChange={setEmail}
          />

          <div>
            <Button
              buttonText="Single Sign-On"
              onClick={onLogin}
              fullWidth
              loading={loading}
            />
          </div>
          <div>
            <Button
              kind={BUTTON_KIND.WHITE}
              buttonText="Cancel"
              onClick={() => setShowSsoLogin(false)}
              fullWidth
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
