import { useCallback, useEffect, useRef, useState } from 'react';

import saasAPI from '@/api/saas';
import { capitalize } from '@/helpers/strings';
import { IPaymentCard } from '@/types/IOrganisation';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';

import { ErrorAlert, SuccessAlert } from '../Alert';
import Button from '../Button';
import InfoPanelContainer from '../InfoPanelContainer';
import InfoPanelDivider from '../InfoPanelDivider';
import PaymentCardItem from './PaymentCardItem';

interface IPaymentMethods {
  orgID: number;
}

const PaymentMethods: React.FC<IPaymentMethods> = ({ orgID }) => {
  const [paymentCards, setPaymentCards] = useState<IPaymentCard[]>([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // Initialize chargebee.js
  window.Chargebee?.init({
    site: import.meta.env.VITE_CHARGEBEE_SITE || '',
    publishableKey: import.meta.env.VITE_CHARGEBEE_KEY || '',
  });

  const cardRef = useRef<ChargebeeComponents>(null);

  const clearCardComponent = () => {
    if (cardRef.current) cardRef.current.clear();
  };

  const listPaymentCards = useCallback(() => {
    setLoading(true);

    saasAPI
      .listPaymentCards({ orgID })
      .then(res => {
        setPaymentCards(res.data?.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [orgID]);

  const tokenize = () => {
    setSuccess('');
    setError('');
    setLoading(true);

    if (cardRef.current) {
      cardRef.current
        .tokenize({})
        .then((data: any) => {
          saasAPI.addPaymentCard(orgID, { token_id: data.token }).then(res => {
            setSuccess(
              `${capitalize(res.data?.data.brand)} Ending in ${
                res.data?.data.last4
              } has been added`,
            );
            listPaymentCards();
            clearCardComponent();
          });
        })
        .catch(() => {
          setError(
            'Problem while tokenizing your card details. Please try again later',
          );
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (orgID) {
      listPaymentCards();
    }
  }, [orgID, listPaymentCards]);

  return (
    <div className="mt-10">
      <InfoPanelContainer
        title="Payment Methods"
        information={
          <>
            These are the payment methods used to charge your subscription.
            <br />
            <br />
            If your default payment fails, we will attempt to charge the card
            again in 48 hours. If the payment method fails again, we will
            deactivate your subscription automatically.
          </>
        }
      >
        {' '}
        {success && <SuccessAlert message={success} />}
        {paymentCards.length > 0
          ? paymentCards.map((card, index) => {
              return (
                <PaymentCardItem
                  key={`Payment-Card-${index}`}
                  brand={card.brand}
                  last4={card.last4}
                  month={card.expiry_month}
                  year={card.expiry_year}
                  role={card.role}
                />
              );
            })
          : 'No payment cards yet.'}
      </InfoPanelContainer>
      <InfoPanelDivider />
      <InfoPanelContainer
        title="Add Payment Method"
        footerContent={() => (
          <>
            <Button
              disabled={loading}
              buttonText="Add payment method"
              onClick={tokenize}
            />
          </>
        )}
      >
        {error && <ErrorAlert message={error} />}
        <CardComponent ref={cardRef} className="fieldset field" />
      </InfoPanelContainer>
    </div>
  );
};

export { PaymentMethods as default };
