export function validateEmail(email: string | null) {
  if (email === null) {
    return false;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateUrl(candidateUrl: string) {
  const protocolRegex = /^[a-zA-Z]+:\/\//;
  if (!protocolRegex.test(candidateUrl)) {
    candidateUrl = `https://${candidateUrl}`;
  }

  try {
    new URL(candidateUrl);
    return true;
  } catch {
    return false;
  }
}

/*
Requires password to have:
  - lowercase letter
  - capital letter
  - a number
  - minimum eight characters
*/
export function validateComplexPassword(password: string) {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(String(password));
}
