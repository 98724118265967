import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import jwtDecode, { JwtPayload } from 'jwt-decode';

import accountsAPI from '@/api/accounts';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Button, { BUTTON_KIND } from '@/components/Button';
import Input from '@/components/Input';
import LayoutStandard from '@/components/LayoutStandard';
import MESSAGES from '@/constants/messages-en';
import { searchParamsQuery } from '@/helpers/urlHandler';
import useAuth from '@/hooks/useAuth';
import usePageHeight from '@/hooks/usePageHeight';

interface IVerifyOTPCodePage {
  location: {
    search: string;
  };
}

const VerifyOTPCodePage: React.FC<IVerifyOTPCodePage> = ({ location }) => {
  const { code } = searchParamsQuery(location.search);
  const {
    verifyMfaCode,
    loading,
    authError,
    setAuthError,
    user,
    redirectToShopify,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [otpCode, setOTPCode] = useState(code || '');
  const [otpCodeError, setOTPCodeError] = useState(false);
  const [otpCodeErrorMessage, setOTPCodeErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('mfa_email') || '');
  const [mfaToken, setMfaToken] = useState(
    localStorage.getItem('mfa_token') || '',
  );
  const pageHeight = usePageHeight();

  const isValidMfaToken = (token: string) => {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      if (!decoded.exp) {
        return false;
      }

      const now = Math.round(new Date().getTime() / 1000);
      return decoded.exp > now;
    } catch (exception) {
      return false;
    }
  };

  useEffect(() => {
    // redirect if user is already logged in
    if (user) {
      redirectToShopify();
    }
  }, [user]);

  if (!email || !mfaToken) {
    return <Redirect to="/shopify/login" />;
  }

  const isValidVerificationCode = (code: string) => {
    const codeRegex = /^\d{6}$/;
    return codeRegex.test(code);
  };

  function handleConfirm() {
    setOTPCodeError(false);
    setAuthError(false);
    setSuccessMessage('');

    if (!isValidVerificationCode(otpCode)) {
      setOTPCodeError(true);
      setOTPCodeErrorMessage(MESSAGES.mfa.code.invalid);

      return;
    }

    if (mfaToken === null || !isValidMfaToken(mfaToken)) {
      setOTPCodeError(true);
      setOTPCodeErrorMessage(MESSAGES.mfa.token.invalid);
      return;
    }

    verifyMfaCode(mfaToken, otpCode);
  }

  function handleResendOTP() {
    setOTPCodeError(false);
    setAuthError(false);
    setSuccessMessage('');

    if (mfaToken === null || !isValidMfaToken(mfaToken)) {
      setOTPCodeError(true);
      setOTPCodeErrorMessage(MESSAGES.mfa.token.invalid);
      return;
    }

    accountsAPI
      .resendMfaToken(mfaToken)
      .then(() => {
        setSuccessMessage(MESSAGES.mfa.code.resend);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <LayoutStandard heading="Verify your email address">
      <div
        className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        style={{ maxHeight: pageHeight }}
      >
        <div className="hidden lg:block bg-white py-8 px-4 border border-gray-100 shadow sm:rounded-lg sm:px-10">
          {authError && <ErrorAlert message="Code is incorrect" />}
          {otpCodeError && <ErrorAlert message={otpCodeErrorMessage} />}
          {successMessage && <SuccessAlert message={successMessage} />}
          <p className="text-gray-400 leading-5 text-base text-center">
            Enter code sent to {email}
          </p>
          <div className="space-y-3 mt-5">
            <Input
              label="Verification code"
              type="text"
              value={otpCode}
              onChange={setOTPCode}
              error={otpCodeError || authError}
              autocomplete="one-time-code"
              inputMode="numeric"
              pattern="\d{6}"
              maxLength={6}
              placeholder="123456"
            />

            <div className="pt-2">
              <Button
                buttonText="Confirm"
                onClick={() => {
                  if (!loading) handleConfirm();
                }}
                fullWidth
                loading={loading}
              />
            </div>

            <div>
              <Button
                kind={BUTTON_KIND.WHITE}
                buttonText="Resend code"
                onClick={() => {
                  if (!isLoading && !loading) handleResendOTP();
                }}
                fullWidth
                loading={isLoading || loading}
              />
            </div>

            <div className="text-center pt-2">
              <Link
                className="text-sm"
                to="/shopify/login"
                replace
                onClick={() => {
                  setAuthError(false);
                  localStorage.removeItem('mfa_token');
                }}
              >
                Go back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LayoutStandard>
  );
};

export default VerifyOTPCodePage;
