import Modal from '../Modals/Modal';

type WarningModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
};

function WarningModal({ open, setOpen, onSuccess }: WarningModalProps) {
  return (
    <Modal
      isOpen={open}
      onSuccess={onSuccess}
      onCancel={() => setOpen(false)}
      dialogTitle="You are about to move a user from one group to another"
      cancelButtonText="Go back"
      successButtonText="Add profiles"
    >
      <div className="mt-4 text-[#4B5563] text-sm">
        You have selected a user who is already part of an existing group.
        Continuing will move them from their current group to this new group.
      </div>
    </Modal>
  );
}

export default WarningModal;
