import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ExclamationCircleIcon } from '@heroicons/react/outline';

import organisationsAPI from '@/api/organisations';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { IOrganisationInvite, IOrganisationUser } from '@/types/IOrganisation';

import { SuccessAlert } from '../Alert';
import InputCheckbox from '../InputCheckbox';
import LoadingAnimation from '../LoadingAnimation';
import ModalFullWidth from '../Modals/ModalFullWidth';
import { EditorItem, EditorItemMobile } from './EditorItem';
import { InviteGroupManager } from './InviteUserButton';

type EditorsModalProps = {
  groupID: number | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  users: IOrganisationUser[] | undefined;
  invitees: string[];
  onSuccess: (
    editors: IOrganisationUser[],
    invites: IOrganisationInvite[],
    invitees: string[],
  ) => void;
};

function EditorsModal({
  groupID,
  isOpen,
  setIsOpen,
  users,
  invitees,
  onSuccess,
}: EditorsModalProps) {
  const { orgID } = useAuth();

  const [selectedEditors, setSelectedEditors] = useState<
    Set<IOrganisationUser>
  >(new Set());
  const [selectedInvites, setSelectedInvites] = useState<
    Set<IOrganisationInvite>
  >(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [inviteSuccessMessage, setInviteSuccessMessage] = useState<
    string | undefined
  >(undefined);

  const { data: invites, isFetching: isInvitesLoading } = useQuery(
    ['listInvites', isOpen],
    listInvites,
    {
      enabled: orgID !== undefined,
      select(data) {
        return data?.data.filter(
          invite =>
            invite.role === 'org_editor' &&
            (groupID === undefined || !invite.group_ids.includes(groupID)),
        );
      },
    },
  );

  useEffect(() => {
    if (invites && users) {
      setIsAllSelected(
        selectedEditors.size + selectedInvites.size ===
          users.length + invites.length,
      );
    }
  }, [selectedEditors, selectedInvites]);

  async function listInvites() {
    if (orgID === undefined) {
      return;
    }

    const res = await organisationsAPI.listInvites({
      orgID,
      page: 1,
      pageSize: 100,
    });

    return res.data;
  }

  function onInviteItemCheckChange(
    selected: boolean,
    invite: IOrganisationInvite,
  ) {
    setSelectedInvites(prev => {
      const next = new Set(prev);
      if (selected) {
        next.add(invite);
      } else {
        next.delete(invite);
      }
      return next;
    });
  }

  function onEditorItemCheckChange(selected: boolean, user: IOrganisationUser) {
    setSelectedEditors(prev => {
      const next = new Set(prev);
      if (selected) {
        next.add(user);
      } else {
        next.delete(user);
      }
      return next;
    });
  }

  function onSelectAllChange(selected: boolean) {
    setIsAllSelected(selected);
    if (selected) {
      setSelectedEditors(prev => {
        const next = new Set(prev);
        users?.forEach(user => next.add(user));
        return next;
      });
    } else {
      setSelectedEditors(new Set());
    }
  }

  function inviteUser(email: string) {
    if (groupID !== undefined) {
      return;
    }

    onSuccess(Array.from(selectedEditors), Array.from(selectedInvites), [
      ...invitees,
      email,
    ]);
    setIsOpen(false);
  }

  return (
    <ModalFullWidth
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Add group manager"
      dialogDescription="Select group manager to add as editors of this group."
      successButtonText="Add to group"
      isDisabled={selectedEditors.size === 0 && selectedInvites.size === 0}
      footerText={`${selectedEditors.size + selectedInvites.size} selected`}
      onSuccess={() => {
        onSuccess(Array.from(selectedEditors), Array.from(selectedInvites), []);
        setSelectedEditors(new Set());
        setSelectedInvites(new Set());
        setIsOpen(false);
      }}
    >
      <div className="space-y-4 pt-4">
        <div className="flex flex-row bg-brand-50 rounded-md gap-2 p-2">
          <ExclamationCircleIcon className="w-5 h-5 text-brand-500 flex-shrink-0" />
          <div className="flex flex-col font-medium text-brand-900 text-sm">
            You can now invite users to become group managers directly from the
            group.
            <span className="font-normal">
              Click the invite new user button to begin!
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <InviteGroupManager
            groupID={groupID}
            callback={() => setIsOpen(false)}
            createGroupCallback={inviteUser}
            setInviteSuccessMessage={setInviteSuccessMessage}
          />
        </div>
        {inviteSuccessMessage && (
          <SuccessAlert message={inviteSuccessMessage} />
        )}
        {users && invites && !isInvitesLoading ? (
          users.length > 0 || invites.length > 0 ? (
            <>
              <div className="border border-solid rounded-lg overflow-hidden hidden xl:block">
                <table className="min-w-full divide-y divide-y-gray-50">
                  <thead className="bg-gray-50 border-b border-gray-200">
                    <tr className="text-gray-900 text-sm uppercase">
                      <th>
                        <InputCheckbox
                          id="select-all"
                          value={isAllSelected}
                          onChange={onSelectAllChange}
                        />
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Managing Groups
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-center"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {invites.map(invite => (
                      <EditorItem
                        key={invite.id}
                        editor={{
                          id: invite.id,
                          name: invite.email,
                          groups: [],
                          status: 'pending',
                        }}
                        isChecked={selectedInvites.has(invite)}
                        onCheckChange={selected =>
                          onInviteItemCheckChange(selected, invite)
                        }
                      />
                    ))}
                    {users.map((user, index) => (
                      <EditorItem
                        key={index}
                        editor={{
                          id: user.id,
                          name: `${user.user.first_name} ${user.user.last_name}`,
                          groups: user.groups,
                          status: 'active',
                        }}
                        isChecked={selectedEditors.has(user)}
                        onCheckChange={selected =>
                          onEditorItemCheckChange(selected, user)
                        }
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="xl:hidden space-y-4">
                <div className="bg-gray-100 border border-gray-300 flex flex-row rounded-md uppercase text-gray-900 font-medium divide-x divide-gray-300 px-2">
                  <InputCheckbox
                    id="select-all"
                    value={isAllSelected}
                    onChange={onSelectAllChange}
                  />
                  <span className="p-2">Name, groups &amp; status</span>
                </div>
                <div className="max-h-[calc(100vh-360px)] overflow-y-scroll space-y-4">
                  {invites.map(invite => (
                    <EditorItemMobile
                      key={invite.id}
                      editor={{
                        id: invite.id,
                        name: invite.email,
                        groups: [],
                        status: 'pending',
                      }}
                      isChecked={selectedInvites.has(invite)}
                      onCheckChange={selected =>
                        onInviteItemCheckChange(selected, invite)
                      }
                    />
                  ))}
                  {users.map((user, index) => (
                    <EditorItemMobile
                      key={index}
                      editor={{
                        id: user.id,
                        name: `${user.user.first_name} ${user.user.last_name}`,
                        groups: user.groups,
                        status: 'active',
                      }}
                      isChecked={selectedEditors.has(user)}
                      onCheckChange={selected =>
                        onEditorItemCheckChange(selected, user)
                      }
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.group_editors.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.group_editors.list.empty.description}
              </p>
            </div>
          )
        ) : (
          <div className="flex justify-center py-4">
            <LoadingAnimation className="w-16 h-16" />
          </div>
        )}
      </div>
    </ModalFullWidth>
  );
}

export default EditorsModal;
