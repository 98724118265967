import { validateUrl } from '@/helpers/validate';

export default {
  mapScreenTitle: 'Map fields from CSV to Tapt',
  mapScreenDescription:
    'Map fields from CSV to corresponding Tapt fields. For CSV fields you do not wish to import, select ‘don’t map this field’ in the Tapt field drop down.',
  importScreenTitle: 'Import mapped fields to Tapt',
  importScreenDescription:
    'Tapt will automatically map all field values for you where possible. If you’d like to individually map field values, return to the previous screen and select the check-box next to the relvent fields ',
  profileTaptFields: [
    { key: 'company_name', title: 'Company name' },
    { key: 'job_title', title: 'Job title' },
    { key: 'pronouns', title: 'Pronouns' },
    {
      key: 'email',
      isValid: (value: unknown) =>
        typeof value === 'string' &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        ),
      title: 'Email',
    },
    {
      key: 'id',
      isValid: (value: unknown) => typeof value === 'number',
      title: 'ID',
    },
    {
      key: 'mobile_number',
      isValid: (value: unknown) =>
        (typeof value === 'string' || typeof value === 'number') &&
        /^(?![ -])(?!.*[-]$)(?!.*[ -]{2})[\+\(\)0-9- ]+$/.test(
          value.toString(),
        ),
      title: 'Mobile number',
    },
    { key: 'middle_name', title: 'Middle name' },
    { key: 'inserted_at', title: 'Inserted at' },
    { key: 'note', title: 'Note' },
    {
      key: 'company_website',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Company website',
    },
    { key: 'post_code', title: 'Post code' },
    {
      key: 'country',
      isValid: (value: unknown) =>
        typeof value === 'string' && /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/.test(value),
      title: 'Country',
    },
    { key: 'city', title: 'City' },
    { key: 'last_name', title: 'Last name' },
    {
      key: 'profile_hash',
      isValid: (value: unknown) =>
        typeof value === 'string' && /^[A-Za-z0-9]{20}$/.test(value),
      title: 'Profile hash',
    },
    { key: 'photo', title: 'Photo' },
    { key: 'first_name', title: 'First name' },
    {
      key: 'profile_page_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Profile page URL',
    },
    { key: 'street_address', title: 'Street address' },
    {
      key: 'company_phone_number',
      isValid: (value: unknown) =>
        (typeof value === 'string' || typeof value === 'number') &&
        /^(?![ -])(?!.*[-]$)(?!.*[ -]{2})[\+\(\)0-9- ]+$/.test(
          value.toString(),
        ),
      title: 'Company phone number',
    },
    {
      key: 'state',
      isValid: (value: unknown) =>
        typeof value === 'string' && /^[A-Za-zÀ-ÖØ-öø-ÿ \-'\.]+$/.test(value),
      title: 'State',
    },
    { key: 'profile_file_links', title: 'Profile file links' },
    {
      key: 'linkedin_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Linkedin URL',
    },
    {
      key: 'instagram_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Instagram URL',
    },
    {
      key: 'facebook_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Facebook URL',
    },
    {
      key: 'tiktok_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Tiktok URL',
    },
    {
      key: 'twitter_url',
      isValid: (value: unknown) =>
        typeof value === 'string' && validateUrl(value),
      title: 'Twitter URL',
    },
    {
      key: 'group_id',
      title: 'Group ID',
    },
  ],
  mappingErrorMessage:
    'Ensure all fields have been mapped. Select mapping option from dropdown.',
  importErrorMessage:
    'Some fields have import errors. Please check your CSV file for errors.',
};
