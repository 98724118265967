import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import clsx from 'clsx';

import organisationsAPI from '@/api/organisations';
import profilesAPI from '@/api/profiles';
import ModalFullWidth from '@/components/Modals/ModalFullWidth';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import useAuth from '@/hooks/useAuth';
import { IOrganisationGroup } from '@/types/IOrganisation';
import IProfile from '@/types/IProfile';

import { ErrorAlert } from '../Alert';
import {
  CheckableProfileGroupCard,
  CheckableProfileItemRow,
} from '../GroupListItem';
import WarningModal from '../GroupPage/WarningModal';
import LoadingAnimation from '../LoadingAnimation';
import Pagination from '../Pagination';

type AddProfilesToGroupModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  checkedProfiles: IProfile[];
  onSuccessCallback?: () => void;
};

export function AddProfilesToGroupModal({
  isOpen,
  setIsOpen,
  checkedProfiles,
  onSuccessCallback,
}: AddProfilesToGroupModalProps) {
  const { orgID } = useAuth();

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [checkedGroup, setCheckedGroup] = useState<
    IOrganisationGroup | undefined
  >(undefined);

  const { data: groups } = useQuery(
    ['listGroups', sort, order, search, page, pageSize, isOpen],
    () => {
      return listGroups(sort, order, search, page, pageSize);
    },
    {
      enabled: orgID !== undefined,
    },
  );
  const {
    mutateAsync: addProfiles,
    isLoading: isAddingProfiles,
    isError,
  } = useMutation({
    mutationFn: assignProfilesToGroup,
    onSuccess: () => {
      if (showWarningModal) {
        setShowWarningModal(false);
      }
      setIsOpen(false);
      setCheckedGroup(undefined);
      onSuccessCallback?.();
    },
  });

  async function listGroups(
    sort: string,
    order: string,
    search: string,
    page: number,
    pageSize: number,
  ) {
    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      sort,
      order,
      search,
      page,
      pageSize,
    });

    return groups;
  }

  async function assignProfilesToGroup() {
    if (orgID === undefined) {
      return;
    }

    if (checkedGroup === undefined) {
      return;
    }

    await profilesAPI.updateProfilesShared({
      orgID,
      body: {
        ids: checkedProfiles.map(({ id }) => id),
        profile: { group_id: checkedGroup.id },
      },
    });
  }

  const profileBelongsToGroup = checkedProfiles.some(
    profile => !!profile.group_id,
  );

  return (
    <>
      <WarningModal
        open={showWarningModal}
        setOpen={setShowWarningModal}
        onSuccess={() => {
          addProfiles();
          setShowWarningModal(false);
        }}
      />
      <ModalFullWidth
        dialogTitle="Add profiles to group"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        successButtonText="Add to group"
        isDisabled={checkedGroup === undefined}
        isLoading={isAddingProfiles}
        onSuccess={
          profileBelongsToGroup ? () => setShowWarningModal(true) : addProfiles
        }
        onCancel={() => setCheckedGroup(undefined)}
      >
        {isError && <ErrorAlert message={MESSAGES.error.generic} />}
        <div className="flex flex-row justify-end flex-shrink-0 space-x-3 items-start py-4">
          <Search
            id="add-profiles-to-group-search"
            search={search}
            setSearch={setSearch}
            fetchQuery={searchQuery =>
              listGroups(sort, order, searchQuery, page, pageSize)
            }
          />
          <Sort
            id="add-profiles-to-group-sort"
            options={[
              {
                sort: 'date',
                order: 'desc',
                label: 'Newest first',
              },
              {
                sort: 'date',
                order: 'asc',
                label: 'Oldest first',
              },
              {
                sort: 'first_name',
                order: 'asc',
                label: 'Name (A-Z)',
              },
              {
                sort: 'first_name',
                order: 'desc',
                label: 'Name (Z-A)',
              },
            ]}
            sort={sort}
            setSort={setSort}
            order={order}
            setOrder={setOrder}
            fetchQuery={(sortQuery, orderQuery) =>
              listGroups(sortQuery, orderQuery, search, page, pageSize)
            }
          />
        </div>
        {groups ? (
          groups.data.length > 0 ? (
            <div className="flex flex-col relative gap-6">
              <div className="hidden xl:block shadow-sm border border-gray-200 min-h-8 max-h-[450px] overflow-y-scroll overflow-hidden">
                <table
                  className={clsx('w-full', {
                    'opacity-40': isPaginationLoading,
                  })}
                >
                  <thead
                    className="bg-gray-100 border-b border-gray-200 sticky top-0"
                    style={{ zIndex: 2 }}
                  >
                    <tr className="uppercase text-gray-900 text-sm">
                      <th scope="col" />
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Group ID
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Managers
                      </th>
                      <th
                        scope="col"
                        className="font-medium py-3 px-6 text-left"
                      >
                        Profiles
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {groups.data.map((group, index) => (
                      <CheckableProfileItemRow
                        key={index}
                        group={group}
                        checked={checkedGroup === group}
                        onCheckChange={() => setCheckedGroup(group)}
                      />
                    ))}
                  </tbody>
                  {isPaginationLoading && (
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                      <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                    </div>
                  )}
                </table>
              </div>
              <div className="xl:hidden space-y-4 max-h-[450px] overflow-y-scroll overflow-hidden">
                <div className="border border-gray-300 bg-gray-100 text-gray-900 text-sm uppercase rounded-md text-center p-2 font-medium">
                  Name, group id, manager &amp; profiles
                </div>
                {groups &&
                  groups.data.map((group, index) => (
                    <CheckableProfileGroupCard
                      key={index}
                      groupHash={group.group_hash}
                      noOfEditors={group.editors.length}
                      noOfProfiles={group.profiles_count}
                      groupName={group.name}
                      checked={checkedGroup === group}
                      onCheckChange={() => setCheckedGroup(group)}
                    />
                  ))}
              </div>
              <Pagination
                id="add-profiles-to-group-pagination"
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setIsLoading={setIsPaginationLoading}
                fetchQuery={(pageQuery, pageSizeQuery) =>
                  listGroups(sort, order, search, pageQuery, pageSizeQuery)
                }
              />
            </div>
          ) : (
            <div className="flex flex-col items-center py-32 space-y-2">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.groups.list.empty.heading}
              </h3>
            </div>
          )
        ) : (
          <div className="h-56 flex items-center">
            <LoadingAnimation className="w-16 h-16 mx-auto" />
          </div>
        )}
      </ModalFullWidth>
    </>
  );
}
