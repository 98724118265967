import { Fragment, useCallback, useEffect, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid';

import { AxiosError } from 'axios';
import { useEffectOnce } from 'usehooks-ts';

import accountsAPI from '@/api/accounts';
import adminAPI from '@/api/admin';
import organisationsAPI from '@/api/organisations';
import { ErrorAlert, SuccessAlert } from '@/components/Alert';
import Badge, { BADGE_KIND } from '@/components/Badge';
import Button, { BUTTON_KIND } from '@/components/Button';
import InfoPanelContainer from '@/components/InfoPanelContainer';
import InfoPanelDivider from '@/components/InfoPanelDivider';
import Input from '@/components/Input';
import InputSelect from '@/components/InputSelect';
import Modal from '@/components/Modals/Modal';
import Search from '@/components/Search';
import PendingInviteItem from '@/components/SettingsPage/PendingInviteItem';
import MESSAGES from '@/constants/messages-en';
import { validateEmail } from '@/helpers/validate';
import IOrganisation, {
  IOrganisationDeleteError,
  IOrganisationGroup,
  IOrganisationInvite,
  IOrganisationUser,
} from '@/types/IOrganisation';

interface IOrganisationSettings {
  orgID: number;
  organisationName: string;
  setOrganisationName: React.Dispatch<React.SetStateAction<string>>;
  inviteUserEmail?: string;
  setInviteUserEmail?: React.Dispatch<React.SetStateAction<string>>;
  inviteUserRole?: string;
  setInviteUserRole?: React.Dispatch<React.SetStateAction<string>>;
  orgUsers: IOrganisationUser[];
  setOrgUsers: React.Dispatch<React.SetStateAction<IOrganisationUser[]>>;
  disabled?: boolean;
}

export default function OrganisationSettings({
  orgID,
  organisationName,
  setOrganisationName,
  inviteUserEmail = '',
  setInviteUserEmail,
  inviteUserRole = 'org_admin',
  setInviteUserRole,
  orgUsers,
  setOrgUsers,
  disabled,
}: IOrganisationSettings) {
  const [selectedAccount, setSelectedAccount] = useState<
    IOrganisationUser | undefined
  >(undefined);
  const [nameLoading, setNameLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [orgSearch, setOrgSearch] = useState('');
  const [moveOrgID, setMoveOrgID] = useState(-1);
  const [orgData, setOrgData] = useState<IOrganisation[]>();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [dataGroup, setDataGroup] = useState<IOrganisationGroup[]>();
  const [dataGroupFiltered, setDataGroupFiltered] =
    useState<IOrganisationGroup[]>();
  const [userID, setUserID] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [invites, setInvites] = useState<IOrganisationInvite[]>([]);

  const [showOptions, setShowOptions] = useState(false);
  const [orgMoveIsOpen, setOrgMoveIsOpen] = useState(false);
  const [inviteUserIsOpen, setInviteUserIsOpen] = useState(false);
  const [deleteAccountIsOpen, setDeleteAccountIsOpen] = useState(false);
  const [deleteEditorIsOpen, setDeleteEditorIsOpen] = useState(false);
  const [resetPasswordIsOpen, setResetPasswordIsOpen] = useState(false);
  const [deleteOrganisationIsOpen, setDeleteOrganisationIsOpen] =
    useState(false);
  const [isDeleteInviteOpen, setIsDeleteInviteOpen] = useState(false);
  const [isAssignToAdminOpen, setIsAssignToAdminOpen] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState<IOrganisationInvite>();
  const [isResendInviteLoading, setIsResendInviteLoading] = useState(false);

  const [accountError, setAccountError] = useState(false);
  const [groupEditorError, setGroupEditorError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emptyOrgError, setEmptyOrgError] = useState('');
  const [nameError, setNameError] = useState(false);
  const [deleteOrgError, setDeleteOrgError] = useState(false);
  const [orgInviteError, setOrgInviteError] = useState('');

  const [nameSuccess, setNameSuccess] = useState('');
  const [groupEditorSuccess, setGroupEditorSuccess] = useState<
    string | undefined
  >(undefined);
  const [accountLoading, setAccountLoading] = useState(false);
  const [adminAccountSuccess, setAdminAccountSuccess] = useState('');
  const [editorAccountSuccess, setEditorAccountSuccess] = useState('');

  const [selectedValues, setSelectedValues] = useState([0]);

  const shouldApplyScrollStyleDropdown =
    dataGroupFiltered !== undefined && dataGroupFiltered.length > 4;

  const listGroups = useCallback(async () => {
    const res = await organisationsAPI.listOrganisationGroups({
      orgID,
      page: 1,
      pageSize: 500,
    });
    setDataGroup(res.data?.data);
    setDataGroupFiltered(res.data?.data);
    return res.data;
  }, [orgID]);

  const listInvites = async (
    newPage: number = page,
    newPageSize: number = pageSize,
  ) => {
    const res = await organisationsAPI.listInvites({
      orgID,
      page: newPage,
      pageSize: newPageSize,
    });
    setInvites(res.data.data);

    return res.data;
  };

  const addEditorToOrg = (roleID: number, groupID: number) => {
    organisationsAPI
      .createGroupEditor(groupID, {
        editor: {
          role_id: roleID,
        },
      })
      .then(() => listGroups())
      .then(() => {
        setGroupEditorSuccess('Group manager assigned to a new group');
      });
  };

  const deleteEditorFromGroup = (editorID: number, groupID: number) => {
    if (orgID !== undefined) {
      organisationsAPI
        .deleteGroupEditor(editorID, groupID)
        .then(() => listGroups())
        .then(() => {
          setGroupEditorSuccess('Group manager removed from group');
        });
    }
  };

  const handleOptionClick = (
    group_editor: IOrganisationUser,
    group: IOrganisationGroup,
  ) => {
    if (selectedValues.includes(group.id)) {
      setSelectedValues(selectedValues.filter(v => v !== group.id));
      group.editors.forEach(option => {
        if (option.user.id === group_editor.user.id) {
          deleteEditorFromGroup(option.id, group.id);
        }
      });
    } else {
      setSelectedValues([...selectedValues, group.id]);
      addEditorToOrg(group_editor.id, group.id);
    }
  };

  const listOrganisations = useCallback(async (newOrgSearch: string) => {
    const res = await adminAPI.listOrganisations({
      pageSize: 5,
      search: newOrgSearch,
    });

    setOrgData(res.data?.data?.filter((item: IOrganisation) => item.name));

    return res.data;
  }, []);

  const handleOrganisationNameSave = () => {
    setNameSuccess('');
    setNameError(false);
    setNameLoading(true);
    organisationsAPI
      .updateOrganisation(orgID, {
        organisation: {
          name: organisationName,
        },
      })
      .then(() => setNameSuccess(MESSAGES.organisation.settings))
      .catch(() => setNameError(true))
      .finally(() => setNameLoading(false));
  };

  const handleInviteUser = () => {
    setAdminAccountSuccess('');
    setEditorAccountSuccess('');
    setEmailError(false);
    setAccountLoading(true);

    if (inviteUserEmail && validateEmail(inviteUserEmail)) {
      setEmailError(false);
      organisationsAPI
        .inviteUser(orgID, {
          invite: {
            organisation_id: orgID,
            email: inviteUserEmail,
            role: inviteUserRole,
            group_ids: [],
          },
        })
        .then(() => {
          if (inviteUserRole === 'org_admin') {
            setAdminAccountSuccess(MESSAGES.organisation.user);
          } else {
            setEditorAccountSuccess(MESSAGES.organisation.user);
          }
          listInvites();
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 422) {
            setOrgInviteError(MESSAGES.organisation.invite.error);
          } else {
            setAccountError(true);
          }
        })
        .then(() => setAccountLoading(false))
        .finally(() => setInviteUserIsOpen(false));
    } else {
      setEmailError(true);
      setAccountLoading(false);
    }
  };

  const showOrganisation = useCallback(() => {
    organisationsAPI
      .showOrganisation(orgID)
      .then(res => {
        setOrganisationName(res?.data?.data?.name);
      })
      .finally(() => setNameLoading(false));
  }, [orgID, setOrganisationName]);

  async function listOrganisationRoles(
    newPage: number = page,
    newPageSize: number = pageSize,
  ) {
    const res = await organisationsAPI.listOrganisationRoles({
      orgID,
      page: newPage,
      pageSize: newPageSize,
    });
    setOrgUsers(res.data?.data);
    setPage(newPage);
    setPageSize(newPageSize);

    return res.data;
  }

  useEffect(() => {
    setDataGroupFiltered(
      dataGroup?.filter(option =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, dataGroup]);

  useEffectOnce(() => {
    showOrganisation();
    listOrganisationRoles();
    listInvites();
    listGroups();
  });

  const handleDeleteRole = (roleID: number) => {
    setEditorAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);
    organisationsAPI
      .deleteOrganisationRole(orgID, roleID)
      .then(() => setEditorAccountSuccess(MESSAGES.account.delete.success))
      .catch(() => setGroupEditorError(true))
      .then(() => setDeleteAccountIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const handleGetRoleIDAndDelete = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      userID: number,
    ) => {
      organisationsAPI
        .showOrganisationRoleFromUserID(
          orgID,
          newPage,
          newPageSize,
          newSort,
          newOrder,
          newSearch,
          userID,
        )
        .then(res => {
          handleDeleteRole(res.data?.data[0].id);
        });
    },
    [handleDeleteRole, order, orgID, page, pageSize, search, sort],
  );

  const isInGroup = async (userID: number, groupHash: string) => {
    return organisationsAPI
      .showOrganisationGroup(orgID, groupHash)
      .then(res => {
        const data = res?.data?.data;
        if (data.editors.some(orgUser => orgUser.user.id === userID)) {
          return data.id;
        }
        return null;
      });
  };

  const handleResetPassword = (email: string) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);

    accountsAPI
      .forgotPassword({ forgot: { email } })
      .then(() => setAdminAccountSuccess(MESSAGES.account.reset.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setResetPasswordIsOpen(false));
  };

  const handleMoveAccount = (roleID: number, organisation_id: number) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);

    adminAPI
      .updateOrganisationRole(roleID, {
        organisation_role: {
          organisation_id,
        },
      })
      .then(() => setAdminAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() => setAccountLoading(false))
      .finally(() => setOrgMoveIsOpen(false));
  };

  const handleAssignToAdmin = (roleID: number, organisation_id: number) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);

    organisationsAPI
      .updateOrganisationRole(organisation_id, roleID, {
        organisation_role: {
          role: 'org_admin',
        },
      })
      .then(() => setAdminAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() =>
        selectedValues.map(item => deleteEditorFromGroup(roleID, item)),
      )
      .then(() => listOrganisationRoles())
      .then(() => setIsAssignToAdminOpen(false));
  };

  const handleAssignToGroupManager = (
    roleID: number,
    organisation_id: number,
  ) => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);

    organisationsAPI
      .updateOrganisationRole(organisation_id, roleID, {
        organisation_role: {
          role: 'org_editor',
        },
      })
      .then(() => setAdminAccountSuccess(MESSAGES.account.organisation.success))
      .catch(() => setAccountError(true))
      .then(() =>
        selectedValues.map(item => deleteEditorFromGroup(roleID, item)),
      )
      .then(() => listOrganisationRoles())
      .then(() => setIsAssignToAdminOpen(false));
  };

  useEffect(() => {
    const groupsListPromises: Promise<number | null>[] = [];
    dataGroupFiltered?.forEach(option => {
      groupsListPromises.push(isInGroup(userID, option.group_hash));
    });

    Promise.all(groupsListPromises).then(groupsList => {
      setSelectedValues(
        groupsList.filter(groupId => groupId !== null) as number[],
      );
    });
  }, [userID, setSelectedAccount]);

  const handleDeleteOrganisation = () => {
    setAdminAccountSuccess('');
    setAccountError(false);
    setOrgInviteError('');
    setEmptyOrgError('');
    setAccountLoading(true);

    adminAPI
      .deleteOrganisation(orgID)
      .then(() => setAdminAccountSuccess(MESSAGES.organisation.delete.success))
      .catch((e: IOrganisationDeleteError) => {
        if (e.response?.status === 422) {
          setEmptyOrgError(e.response.data.errors[0]);
        } else {
          setDeleteOrgError(true);
        }
      })
      .then(() => setDeleteOrganisationIsOpen(false))
      .then(() => listOrganisationRoles())
      .finally(() => setAccountLoading(false));
  };

  const getGroupCount = (orgUser: IOrganisationUser) => {
    if (!dataGroup) return 0;

    return dataGroup?.reduce((acc, group) => {
      if (group.editors.some(editor => editor.user.id === orgUser.user.id)) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const handleResendInvite = (invite: IOrganisationInvite) => {
    setIsResendInviteLoading(true);
    setAdminAccountSuccess('');
    setOrgInviteError('');

    organisationsAPI
      .inviteUser(orgID, {
        invite: {
          email: invite.email,
          organisation_id: orgID,
          role: invite.role,
          group_ids: [],
        },
      })
      .then(() => {
        setAdminAccountSuccess(
          MESSAGES.organisation.invite.resend.success(invite.email),
        );
      })
      .catch(() => {
        setOrgInviteError(MESSAGES.organisation.invite.resend.error);
      })
      .finally(() => {
        setIsResendInviteLoading(false);
      });
  };

  const handleDeleteInvite = (invite: IOrganisationInvite) => {
    organisationsAPI
      .deleteInvite(orgID, invite.id)
      .then(() => {
        setAdminAccountSuccess(
          MESSAGES.organisation.invite.delete.success(invite.email),
        );
        listInvites();
      })
      .catch(() => {
        setOrgInviteError(MESSAGES.organisation.invite.delete.error);
      });
  };

  const onDeleteInvite = (invite: IOrganisationInvite) => {
    setInviteToDelete(invite);
    setIsDeleteInviteOpen(true);
  };

  const numberOfAdmins = orgUsers.filter(
    user => user.role === 'org_admin',
  ).length;

  return (
    <>
      {setInviteUserEmail && setInviteUserRole && (
        <Modal
          isOpen={inviteUserIsOpen}
          setIsOpen={setInviteUserIsOpen}
          dialogTitle="Invite user"
          dialogDescription={
            inviteUserRole === 'org_editor'
              ? MESSAGES.organisation.invite.subtext.editor
              : MESSAGES.organisation.invite.subtext.admin
          }
          successButtonText="Send email invitation"
          onSuccess={() => handleInviteUser()}
          isLoading={accountLoading}
        >
          <div className="mt-6">
            {emailError && <ErrorAlert message={MESSAGES.error.email} />}
            <Input
              label="Email"
              value={inviteUserEmail}
              onChange={setInviteUserEmail}
              type="text"
              placeholder="email@example.com"
            />
            <div className="mt-6">
              <InputSelect
                id="role"
                label="Role"
                value={inviteUserRole}
                onChange={setInviteUserRole}
                options={[
                  {
                    label: 'Account administrator',
                    value: 'org_admin',
                  },
                  {
                    label: 'Group Manager',
                    value: 'org_editor',
                  },
                ]}
              />
            </div>
          </div>
        </Modal>
      )}
      <Modal
        isOpen={deleteAccountIsOpen}
        setIsOpen={setDeleteAccountIsOpen}
        dialogTitle={MESSAGES.account.delete.heading}
        dialogDescription={MESSAGES.account.delete.description(
          selectedAccount?.user.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount) handleDeleteRole(selectedAccount?.id);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={deleteEditorIsOpen}
        setIsOpen={setDeleteEditorIsOpen}
        dialogTitle="Are you sure you want to delete this group manager"
        dialogDescription={MESSAGES.account.delete.descriptionEditor(
          selectedAccount?.user.email || '',
        )}
        successButtonText="Delete account"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (selectedAccount)
            handleGetRoleIDAndDelete(
              1,
              10,
              '',
              '',
              '',
              selectedAccount?.user.id,
            );
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={resetPasswordIsOpen}
        setIsOpen={setResetPasswordIsOpen}
        dialogTitle={MESSAGES.account.reset.heading}
        dialogDescription={MESSAGES.account.reset.description}
        successButtonText="Reset password"
        onSuccess={() => {
          if (selectedAccount) handleResetPassword(selectedAccount?.user.email);
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={orgMoveIsOpen}
        setIsOpen={setOrgMoveIsOpen}
        dialogTitle={MESSAGES.account.organisation.heading}
        dialogDescription={MESSAGES.account.organisation.description}
        successButtonText="Move account"
        onSuccess={() => {
          if (selectedAccount) {
            handleMoveAccount(selectedAccount?.id, moveOrgID);
          }
        }}
        onCancel={() => setSelectedAccount(undefined)}
        isDisabled={moveOrgID === -1}
        isLoading={accountLoading}
      >
        <div className="pt-4 relative">
          <Search
            id={`OrganisationList-1-20-date-desc--${orgSearch}`}
            placeholder="Search for organisations"
            search={orgSearch}
            setSearch={value => {
              if (value !== orgSearch) {
                setMoveOrgID(-1);
                setShowOptions(true);
              }
              setOrgSearch(value);
            }}
            fetchQuery={newOrgSearch => listOrganisations(newOrgSearch)}
          />
          {orgSearch && showOptions && (
            <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
              <div className="py-1">
                {orgData && orgData.length > 0 ? (
                  orgData.map(item => (
                    <button
                      type="button"
                      key={item.id}
                      className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                      onClick={() => {
                        setMoveOrgID(item.id);
                        setShowOptions(false);
                        setOrgSearch(`#${item.id} ${item.name}`);
                      }}
                    >
                      #{item.id}{' '}
                      <span className="text-gray-500">{item.name}</span>
                    </button>
                  ))
                ) : (
                  <li className="px-3 py-2 text-center">
                    No matching items found
                  </li>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={deleteOrganisationIsOpen}
        setIsOpen={setDeleteOrganisationIsOpen}
        dialogTitle={MESSAGES.organisation.delete.heading}
        dialogDescription={MESSAGES.organisation.delete.description}
        successButtonText="Delete organisation"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (orgID) handleDeleteOrganisation();
        }}
        isLoading={accountLoading}
      />
      <Modal
        isOpen={isDeleteInviteOpen}
        setIsOpen={setIsDeleteInviteOpen}
        dialogTitle={MESSAGES.organisation.invite.delete.heading}
        dialogDescription={MESSAGES.organisation.invite.delete.description(
          inviteToDelete?.email || 'This user',
        )}
        successButtonText="Delete invite"
        successButtonKind={BUTTON_KIND.RED}
        onSuccess={() => {
          if (inviteToDelete) handleDeleteInvite(inviteToDelete);
          setIsDeleteInviteOpen(false);
        }}
        onCancel={() => {
          setIsDeleteInviteOpen(false);
          setInviteToDelete(undefined);
        }}
        isLoading={accountLoading}
      />
      <div className="mt-10 mb-24">
        <InfoPanelContainer
          title="Organisation settings"
          footerContent={() =>
            !disabled && (
              <Button
                buttonText="Save"
                loading={nameLoading}
                onClick={handleOrganisationNameSave}
              />
            )
          }
        >
          {nameSuccess !== '' && <SuccessAlert message={nameSuccess} />}
          {nameError && (
            <ErrorAlert message="Something went wrong. Please try again later" />
          )}
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label="Organisation name"
                type="text"
                value={organisationName}
                required
                onChange={setOrganisationName}
                disabled={disabled}
              />
            </div>
          </div>
        </InfoPanelContainer>
        <InfoPanelDivider />
        <InfoPanelContainer
          title="Admin accounts"
          information={
            <span className="space-y-4">
              You can assign accounts as administrative accounts. To add an
              account as an admin or group manager, click the invite button.
            </span>
          }
        >
          {adminAccountSuccess !== '' && (
            <SuccessAlert message={adminAccountSuccess} />
          )}
          {accountError && <ErrorAlert message={MESSAGES.error.generic} />}
          {orgInviteError !== '' && <ErrorAlert message={orgInviteError} />}
          {emptyOrgError !== '' && <ErrorAlert message={emptyOrgError} />}
          {editorAccountSuccess !== '' && (
            <SuccessAlert message={editorAccountSuccess} />
          )}
          {groupEditorError && (
            <ErrorAlert message="Please ensure you have removed this user as a group manager from all groups before deletion" />
          )}
          {groupEditorSuccess && <SuccessAlert message={groupEditorSuccess} />}
          <div>
            <ul className="divide-y divide-gray-200 h-full">
              {orgUsers &&
                orgUsers.map(
                  item =>
                    item.role === 'org_admin' && (
                      <li key={item.id}>
                        <div className="block bg-white">
                          <div className="flex flex-row items-center justify-between py-4">
                            <div className="min-w-0 text-sm">
                              <p className="font-medium text-gray-900 truncate">
                                {item.user?.first_name} {item.user?.last_name}
                              </p>
                              <p className="text-gray-500 truncate">
                                {item.user?.email}
                              </p>
                            </div>
                            <div className="inline-flex flex-row space-x-6 items-center">
                              <Menu
                                as="div"
                                className="relative text-left mt-2 lg:mt-0"
                              >
                                <div>
                                  <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                    Admin
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute origin-top-right right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="py-1">
                                      {numberOfAdmins > 1 ? (
                                        <Menu.Item>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleAssignToGroupManager(
                                                item.id,
                                                item.organisation_id,
                                              );
                                            }}
                                            className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                          >
                                            Assign as group manager
                                          </button>
                                        </Menu.Item>
                                      ) : null}
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setResetPasswordIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Reset password
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setDeleteAccountIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Delete account
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </li>
                    ),
                )}

              {orgUsers &&
                orgUsers.map(
                  item =>
                    item.role === 'org_editor' && (
                      <li key={item.id}>
                        <Modal
                          isOpen={isAssignToAdminOpen}
                          setIsOpen={setIsAssignToAdminOpen}
                          dialogTitleIcon={
                            <ExclamationCircleIcon className="h-16 w-16 text-red-450 stroke-1" />
                          }
                          dialogTitle="This user will gain access to all groups and ability to edit settings across the whole organisation."
                          successButtonText="Make admin"
                          onSuccess={() => {
                            handleAssignToAdmin(item.id, item.organisation_id);
                          }}
                        />
                        <div className="block">
                          <div className="flex flex-row items-center justify-between py-4 space-x-2">
                            <div className="min-w-0 text-sm">
                              <p className="font-medium text-gray-900 truncate">
                                {item.user.first_name} {item.user.last_name}
                              </p>
                              <p className="text-gray-500 truncate">
                                {item.user.email}
                              </p>
                            </div>
                            <div className="inline-flex flex-col lg:flex-row lg:space-x-6 items-end lg:items-center">
                              <Menu
                                as="div"
                                className="relative inline-block text-left mt-2 lg:mt-0"
                              >
                                <Menu.Button
                                  className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                                  onClick={() => {
                                    if (userID !== item.user.id) {
                                      setSelectedValues([]);
                                      setUserID(item.user.id);
                                      setSearchTerm('');
                                      listGroups();
                                    }
                                  }}
                                >
                                  <Badge
                                    kind={BADGE_KIND.GRAY}
                                    className="mr-2"
                                    text={getGroupCount(item).toString()}
                                  />
                                  Groups
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden"
                                    style={
                                      shouldApplyScrollStyleDropdown
                                        ? {
                                            height: '200px',
                                            overflowY: 'scroll',
                                          }
                                        : {}
                                    }
                                  >
                                    <div>
                                      <div className="sticky top-0 bg-white z-20 px-3 py-2">
                                        <Input
                                          id="search"
                                          label=""
                                          description=""
                                          placeholder="Search"
                                          type="text"
                                          autocomplete="off"
                                          value={searchTerm}
                                          onChange={setSearchTerm}
                                          icon={SearchIcon}
                                        />
                                      </div>
                                      {dataGroupFiltered?.map(
                                        (option, index) => (
                                          <Menu.Item key={index}>
                                            <div
                                              className="flex items-center py-3 pl-3 pr-4 hover:bg-gray-100 cursor-pointer"
                                              onClick={() =>
                                                handleOptionClick(item, option)
                                              }
                                            >
                                              <input
                                                id={`checkbox-${option.id}`}
                                                type="checkbox"
                                                className="w-4 h-4 text-brand-500 focus:outline-none border-gray-300 rounded"
                                                checked={selectedValues.includes(
                                                  option.id,
                                                )}
                                                readOnly
                                              />
                                              <span className="ml-3 text-sm text-gray-700">
                                                {option.name}
                                              </span>
                                            </div>
                                          </Menu.Item>
                                        ),
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                              <Menu
                                as="div"
                                className="relative inline-block text-left mt-2 lg:mt-0"
                              >
                                <Menu.Button className="inline-flex justify-center w-max rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                                  Group Manager
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute origin-top-right right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                    <div className="py-1">
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setIsAssignToAdminOpen(true);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Assign as admin
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setResetPasswordIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-gray-700 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Reset password
                                        </button>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setDeleteEditorIsOpen(true);
                                            setSelectedAccount(item);
                                          }}
                                          className="appearance-none text-red-500 w-full text-left block px-4 py-2 text-sm"
                                        >
                                          Delete account
                                        </button>
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      </li>
                    ),
                )}

              {invites?.map((item, index) => (
                <PendingInviteItem
                  key={index}
                  invite={item}
                  onResendInvite={handleResendInvite}
                  isResendInviteLoading={isResendInviteLoading}
                  onDeleteInvite={onDeleteInvite}
                />
              ))}
            </ul>
            <div className="pt-6 w-full flex justify-end">
              {setInviteUserEmail && setInviteUserRole && (
                <Button
                  kind={BUTTON_KIND.WHITE}
                  buttonText="Invite user"
                  onClick={() => {
                    setInviteUserIsOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        </InfoPanelContainer>
        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Login"
              information="Click this button to log into this organisation account."
            >
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Switch to Organisation view"
                    href={`/?scope=user&uOrgID=${orgID}`}
                    external
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
        {disabled && (
          <>
            <InfoPanelDivider />
            <InfoPanelContainer
              title="Delete organisation"
              information="Click this button to delete this organisation permanently."
            >
              {deleteOrgError && (
                <ErrorAlert message={MESSAGES.error.generic} />
              )}
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Button
                    kind={BUTTON_KIND.RED}
                    buttonText="Delete Organisation"
                    onClick={() => setDeleteOrganisationIsOpen(true)}
                  />
                </div>
              </div>
            </InfoPanelContainer>
          </>
        )}
      </div>
    </>
  );
}
