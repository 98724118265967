import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useQuery } from 'react-query';

import { InformationCircleIcon, UserIcon } from '@heroicons/react/outline';

import profilesAPI from '@/api/profiles';
import SimpleAnalytics from '@/components/Analytics/SimpleAnalytics';
import useAuth from '@/hooks/useAuth';
import { AnalyticsResponse } from '@/types/IAnalytics';

import Modal from '../Modals/Modal';
import EventsChart from './Charts/Events';
import EventsByGeo from './Charts/EventsByGeo';
import MostActiveProfiles from './Charts/MostActiveProfiles';
import TopReferrers from './Charts/TopReferrers';
import TopSocialLinks from './Charts/TopSocialLinks';
import DateFilter from './DateFilter';
import GroupFilter from './GroupFilter';
import type { Props as GroupFilterProps } from './GroupFilter';
import ProfileFilter from './ProfileFilter';
import type { Props as ProfileFilterProps } from './ProfileFilter';
import './react-datepicker.css';

type AnalyticsDashboardProps = {
  data: AnalyticsResponse;
  refetchData?: (
    from?: string,
    to?: string,
    groupIds?: number[],
    profileIds?: number[],
  ) => Promise<void>;
  listProfiles: ProfileFilterProps['listProfiles'];
  listGroups: GroupFilterProps['listGroups'];
};

function AnalyticsDashboard({
  data: {
    profile_views: profileViews,
    contacts_sent: contactsSent,
    contacts_received: contactsReceived,
    most_active_profiles: mostActiveProfiles,
    top_referrers: topReferrers,
    top_social_links: topSocialLinks,
    events_by_geo: eventsByGeo,
  },
  refetchData,
  listProfiles,
  listGroups,
}: AnalyticsDashboardProps) {
  const { orgID } = useAuth();

  const [from, setFrom] = useState<string | undefined>();
  const [to, setTo] = useState<string | undefined>();
  const [groupIds, setGroupIds] = useState<number[]>([]);
  const [profileIds, setProfileIds] = useState<number[]>();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [profileCount, setProfileCount] = useState<number | undefined>(
    undefined,
  );

  useQuery('listProfiles', getProfileCount, {
    enabled: orgID !== undefined,
  });

  const refetchProfiles = (ids: number[]) => {
    setProfileIds(ids);
    refetchData?.(from, to, groupIds, ids);
  };

  async function getProfileCount() {
    const { data: profiles } = await profilesAPI.listProfiles({ orgID });

    setProfileCount(profiles.paging.total_entries);
  }

  return (
    <div className="mb-8 md:mb-0">
      <Modal
        isOpen={showDisclaimer}
        setIsOpen={setShowDisclaimer}
        dialogTitle="Disclaimer"
        dialogDescription="Advanced Analytics only displays data from the 1st of January 2023 onwards."
        cancelButtonText="Close"
        onSuccess={() => {}}
      />
      <div className="-mt-24 mb-6 hidden xl:flex flex-row-reverse">
        <div className="bg-brand-100 text-brand-900 text-sm rounded-md p-4 flex flex-row w-1/2 gap-2">
          <div className="flex-shrink-0">
            <InformationCircleIcon width={20} className="text-brand-500" />
          </div>
          <div className="flex flex-col">
            <span className="font-medium">Notice for existing Tapt users</span>
            <span>
              Advanced Analytics only displays data from the 1st of January 2023
              onwards.
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-col flex-wrap items-end pt-8 md:pt-0 pb-4 md:pb-8">
        <div className="flex gap-2 flex-row-reverse md:flex-row flex-wrap items-center text-center">
          <ProfileFilter
            fetchData={ids => {
              setProfileIds(ids);
              refetchData?.(from, to, groupIds, ids);
            }}
            listProfiles={listProfiles}
          />
          <GroupFilter
            fetchData={ids => {
              setGroupIds(ids);
              refetchData?.(from, to, ids, profileIds);
            }}
            listGroups={listGroups}
          />
          <DateFilter
            fetchData={(fromDate, toDate) => {
              setFrom(fromDate);
              setTo(toDate);
              refetchData?.(fromDate, toDate, groupIds, profileIds);
            }}
          />
          {from === undefined && to === undefined && (
            <button
              onClick={() => setShowDisclaimer(true)}
              type="button"
              className="w-[27px] h-[27px] text-brand-500 cursor-pointer rounded-full font-medium xl:hidden"
            >
              <InformationCircleIcon width={27} height={27} />
            </button>
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-7 mt-2 md:mt-0">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
              Domain Analytics
            </h2>
            <span className="text-gray-500 text-sm pt-2">
              Profiles with the most activity (profile views, contacts sent and
              contacts received)
            </span>
          </div>
          <div className="flex flex-col">
            <span className="flex flex-row items-center gap-1 text-gray-500">
              <UserIcon width={20} height={20} />
              Profiles
            </span>
            {profileCount === undefined ? (
              <span className="self-end">
                <Skeleton width={45} height={28} />
              </span>
            ) : (
              <span className="text-right font-medium text-2xl">
                {profileCount}
              </span>
            )}
          </div>
        </div>
        <span className="text-gray-500 mt-2 hidden md:show">
          Data based on profile views, contacts sent and contacts received
        </span>
        <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
        <div className="grid md:grid-cols-3 gap-6 md:gap-10 w-full">
          <EventsChart
            title="Total profile views"
            total={profileViews.total}
            percent={profileViews.change}
            data={{
              labels: Object.keys(profileViews.entries),
              series: [
                { name: 'Views', data: Object.values(profileViews.entries) },
              ],
            }}
            dateFilter={{ from, to }}
          />
          <EventsChart
            title="Total contacts sent"
            total={contactsSent.total}
            percent={contactsSent.change}
            dateFilter={{ from, to }}
            data={{
              labels: Object.keys(contactsSent.entries),
              series: [
                { name: 'Views', data: Object.values(contactsSent.entries) },
              ],
            }}
          />
          <EventsChart
            title="Total contacts recieved"
            total={contactsReceived.total}
            percent={contactsReceived.change}
            data={{
              labels: Object.keys(contactsReceived.entries),
              series: [
                {
                  name: 'Views',
                  data: Object.values(contactsReceived.entries),
                },
              ],
            }}
            dateFilter={{ from, to }}
          />
        </div>
      </div>
      {mostActiveProfiles.length > 1 && (
        <div className="grid grid-cols-5 gap-4">
          <div className="bg-white rounded-lg p-4 md:p-7 mt-4 col-span-5 md:col-span-5">
            <MostActiveProfiles
              data={mostActiveProfiles}
              title="Most Active Profiles"
              subtitle="Data based on profile views, contacts sent and contacts received"
              mobileSubtitle="Profiles with the most activity"
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white rounded-lg p-4 md:p-7 mt-4 col-span-4 md:col-span-2">
          <TopReferrers
            data={topReferrers}
            dateFilter={{ from, to }}
            title="Top Referrers"
            subtitle="Last month website stats"
            mobileSubtitle="Website statistics"
          />
        </div>
        <div className="bg-white rounded-lg p-4 md:p-7 md:mt-4 col-span-4 md:col-span-2">
          <TopSocialLinks
            data={topSocialLinks}
            title="Social Links"
            subtitle="Most clicked social media"
          />
        </div>
      </div>
      <div className="bg-white rounded-lg p-4 md:p-7 mt-4 flex col-span-12 md:col-span-12">
        <EventsByGeo
          data={eventsByGeo}
          title="Tap Geolocation"
          subtitle="Location of profile views, contacts sent and contacts recieved"
          mobileSubtitle="Location of profile views"
        />
      </div>
      {profileCount && profileCount > 1 && (
        <div className="bg-white rounded-lg p-4 md:p-7 mt-4 flex flex-col col-span-12 md:col-span-12">
          <h2 className="text-gray-900 font-poppins font-medium text-2xl md:text-2xl">
            Profile Analytics
          </h2>
          <h3 className="text-gray-500 pt-2 text-sm hidden md:block">
            Overview of profile analytics. Click {'\u2018'}visit profile
            analytics
            {'\u2018'} to view the analytics of the desired profile with more
            detail.
          </h3>
          <h3 className="text-gray-500 mt-2 md:hidden">
            Overview of individual profile analytics
          </h3>
          <hr className="h-px mt-4 mb-8 bg-gray-200 border-0" />
          <SimpleAnalytics setProfileIds={refetchProfiles} />
        </div>
      )}
    </div>
  );
}

export default AnalyticsDashboard;
