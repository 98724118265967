import React from 'react';

import Button, { BUTTON_KIND } from '@/components/Button';
import Modal from '@/components/Modals/Modal';

interface PurchasingAdditionalCardModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export default function PurchasingAdditionalCardModal(
  props: PurchasingAdditionalCardModalProps,
): JSX.Element {
  const { isOpen, setIsOpen } = props;
  const shopUrl =
    (import.meta.env.VITE_SHOP_BASE_URL as string) || 'https://tapt.test:3001';

  return (
    <Modal
      isOpen={isOpen}
      cancelButtonText={'Close'}
      successButtonText={'Learn more about the platform fee'}
      successButton={
        <Button
          kind={BUTTON_KIND.PRIMARY}
          buttonText={'Learn more about the platform fee'}
          external
          externalBlankTarget
          href={`${shopUrl}/pages/pricing`}
        />
      }
      onSuccess={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      onClose={() => setIsOpen(false)}
    >
      <div className="text-2xl not-italic leading-8 text-gray-900 max-md:max-w-full">
        Platform Fee and Pricing Structure
      </div>
      <article className="mt-3 leading-5 text-sm text-gray-600">
        <p>
          Tapt's platform utilisation fee is designed to aid in Tapt's continual
          investment into the software, features, and analytics that make it the
          best digital business card and associated software available on the
          market. This platform fee is only charged to organisations who have 5
          or more cards with Tapt.
        </p>
        <br />
        <p>
          The platform fee is then determined by how many cards and/or profiles
          the organisation has, and is charged annually. If additional cards are
          purchased during this annual term, this fee may change (on a pro rata
          basis) depending on the new total number of cards in the organisation.
        </p>
        <br />
        <p>
          Click below for more information about our platform utilisation fee
          and the pricing structure for each Tapt product.
        </p>
      </article>
    </Modal>
  );
}
