export const MAX_UPLOAD_FILE_SIZE = 8000000;

export enum PROFILE_IMAGE {
  MAX_HEIGHT = 250,
  MAX_WIDTH = 250,
}

export enum COVER_IMAGE {
  MAX_HEIGHT = 440,
  MAX_WIDTH = 640,
}