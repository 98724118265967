import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import clsx from 'clsx';

import contactsAPI from '@/api/contacts';
import organisationsAPI from '@/api/organisations';
import Button, { BUTTON_KIND } from '@/components/Button';
import ContactsListItem from '@/components/ContactsListItem';
import Filter, { GroupFilterOption } from '@/components/Filter';
import Layout from '@/components/Layout';
import LoadingAnimation from '@/components/LoadingAnimation';
import Pagination from '@/components/Pagination';
import Search from '@/components/Search';
import Sort from '@/components/Sort';
import MESSAGES from '@/constants/messages-en';
import { downloadBlob } from '@/helpers/file';
import useAuth from '@/hooks/useAuth';

const DEFAULT_GROUP_OPTION = {
  id: 0,
  name: 'All groups',
};

export default function ContactListPage() {
  const { user, orgID, userRole } = useAuth();

  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState('date');
  const [order, setOrder] = useState('desc');
  const [search, setSearch] = useState('');
  const [filterOptions, setFilterOptions] = useState<GroupFilterOption[]>([]);
  const [editorsGroup, setEditorsGroup] = useState<GroupFilterOption[]>([]);
  const [selectedGroup, setSelectedGroup] =
    useState<GroupFilterOption>(DEFAULT_GROUP_OPTION);

  const { isFetching: isGroupsFetching, isFetched: isGroupsReady } = useQuery(
    'listGroups',
    listGroups,
    { enabled: orgID !== undefined && user !== undefined },
  );
  const { data: contacts } = useQuery(
    [
      'listContacts',
      page,
      pageSize,
      sort,
      order,
      search,
      selectedGroup.id,
      editorsGroup,
    ],
    () => {
      return listContacts();
    },
    { enabled: isGroupsReady && !isGroupsFetching },
  );

  async function exportCSV() {
    if (orgID === undefined) {
      return;
    }

    const response = await contactsAPI.exportContactsCSV(orgID);
    const blob = new Blob([response.data]);

    downloadBlob(blob, 'contacts.csv');
  }

  async function listGroups() {
    if (orgID === undefined || user === undefined) {
      return;
    }

    const { data: groups } = await organisationsAPI.listOrganisationGroups({
      orgID,
      pageSize: 100,
    });

    const groupList: GroupFilterOption[] = [];

    groups.data.forEach(group => {
      if (group.editors.length > 0) {
        group.editors.forEach(editor => {
          if (editor.user.id === user.id) {
            groupList.push({ id: group.id, name: group.name });
          }

          if (groupList.length === 0 && editor.user.id === user.id) {
            setSelectedGroup({ name: group.name, id: group.id });
          }
        });
      }
    });

    setEditorsGroup(groupList);

    if (userRole === 'org_admin') {
      setFilterOptions([
        DEFAULT_GROUP_OPTION,
        ...groups.data.map(group => ({
          id: group.id,
          name: group.name,
        })),
      ]);
    } else if (userRole === 'org_editor') {
      setFilterOptions([
        DEFAULT_GROUP_OPTION,
        ...groupList.map(group => ({
          id: group.id,
          name: group.name,
        })),
      ]);
    }

    return groups;
  }

  const listContacts = useCallback(
    async (
      newPage: number = page,
      newPageSize: number = pageSize,
      newSort: string = sort,
      newOrder: string = order,
      newSearch: string = search,
      newGroupID: number = selectedGroup.id,
    ) => {
      if (userRole === 'org_editor' && editorsGroup.length === 0) {
        return {
          data: [],
          paging: {
            page_number: 0,
            page_offset: 0,
            page_size: pageSize,
            total_entries: 0,
            total_pages: 0,
          },
        };
      }

      // if fetching all groups, get only the groups that the user is an editor of
      if (newGroupID === 0) {
        const { data: contacts } = await contactsAPI.listContactsInGroups({
          orgID,
          page: newPage,
          pageSize: newPageSize,
          sort: newSort,
          search: newSearch,
          groupIDs: editorsGroup.map(group => group.id),
        });

        setPage(newPage);
        setPageSize(newPageSize);
        setOrder(newOrder);
        setSort(newSort);

        return contacts;
      } else {
        const { data: contacts } = await contactsAPI.listContactsInGroup({
          orgID,
          page: newPage,
          pageSize: newPageSize,
          sort: newSort,
          order: newOrder,
          search: newSearch,
          groupID: newGroupID,
        });

        setPage(newPage);
        setPageSize(newPageSize);
        setOrder(newOrder);
        setSort(newSort);

        return contacts;
      }
    },
    [orgID, page, pageSize, search, sort, order, selectedGroup, editorsGroup],
  );

  return (
    <Layout pageName="Contacts">
      <div className="pb-8 pt-6 md:pt-0">
        <div className="pb-8 flex flex-col space-y-4 items-start lg:flex-row lg:space-y-0 lg:space-x-8 lg:items-end lg:justify-end">
          <div className="flex flex-shrink-0 space-y-3 flex-col lg:items-end">
            <div className="flex flex-shrink-0 space-x-3">
              <Search
                id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}`}
                search={search}
                setSearch={setSearch}
                fetchQuery={searchQuery =>
                  listContacts(
                    page,
                    pageSize,
                    sort,
                    order,
                    searchQuery,
                    selectedGroup.id,
                  )
                }
                fetchEnabled={!isGroupsFetching}
              />
              <Filter
                id={`ContactsListFilter-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
                filter="groups"
                selected={selectedGroup}
                setSelected={setSelectedGroup}
                data={filterOptions}
              />
              <Sort
                id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
                options={[
                  {
                    sort: 'date',
                    order: 'desc',
                    label: 'Date created (Newest first)',
                  },
                  {
                    sort: 'date',
                    order: 'asc',
                    label: 'Date created (Oldest first)',
                  },
                  {
                    sort: 'first_name',
                    order: 'asc',
                    label: 'Name (A-Z)',
                  },
                  {
                    sort: 'first_name',
                    order: 'desc',
                    label: 'Name (Z-A)',
                  },
                ]}
                sort={sort}
                setSort={setSort}
                order={order}
                setOrder={setOrder}
                fetchQuery={(sortQuery, orderQuery) =>
                  listContacts(
                    page,
                    pageSize,
                    sortQuery,
                    orderQuery,
                    search,
                    selectedGroup.id,
                  )
                }
                fetchQueryEnabled={!isGroupsFetching}
              />
              <Button
                kind={BUTTON_KIND.WHITE}
                buttonText="Export via CSV"
                onClick={exportCSV}
              />
            </div>
          </div>
        </div>
        {contacts ? (
          contacts.data.length > 0 ? (
            <div className="flex flex-col">
              <div className="relative -mx-4 sm:-mx-6 md:mx-0 lg:-mx-8">
                <div className="block w-full lg:px-8">
                  <div className="relative shadow-sm border border-gray-200 sm:rounded-md min-h-8 overflow-hidden">
                    <ul
                      className={clsx('divide-y divide-gray-200', {
                        'opacity-40': isPaginationLoading,
                      })}
                    >
                      <li key="ContactsListHeader" className="hidden md:block">
                        <div className="bg-gray-50">
                          <div className="flex items-center px-4 py-2 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-start md:items-center">
                              <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-4 xl:grid-cols-6 md:gap-4">
                                <p className="md:col-span-2 text-sm font-medium text-gray-900 uppercase">
                                  Name &amp; email
                                </p>
                                <p className="mt-2 md:mt-0 hidden xl:block xl:col-span-2 text-sm font-medium text-gray-900 uppercase">
                                  Position &amp; company
                                </p>
                                <p className="mt-2 md:mt-0 text-sm font-medium text-gray-900 uppercase">
                                  Mobile
                                </p>
                                <p className="mt-2 md:mt-0 text-sm font-medium text-gray-900 uppercase">
                                  Connection
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {contacts.data.map((contact, index) => (
                        <ContactsListItem key={index} contact={contact} />
                      ))}
                    </ul>
                    {isPaginationLoading && (
                      <div className="absolute text-gray-500 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-32">
              <h3 className="w-full text-center text-2xl leading-8 text-gray-900 font-medium">
                {MESSAGES.contact.list.empty.heading}
              </h3>
              <p className="w-full text-center mt-2 text-sm leading-5 text-gray-500">
                {MESSAGES.contact.list.empty.description}{' '}
                <a
                  href="https://tapt.io/pages/how-to-use"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more.
                </a>
              </p>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center min-h-screen py-6 bg-white sm:px-6 lg:px-8">
            <div>
              <LoadingAnimation className="w-16 h-16 mx-auto text-brand-500" />
            </div>
          </div>
        )}
      </div>
      <Pagination
        id={`ContactsList-${page}-${pageSize}-${sort}-${order}-${search}-${selectedGroup.id}`}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchQuery={(pageQuery, pageSizeQuery) =>
          listContacts(
            pageQuery,
            pageSizeQuery,
            sort,
            order,
            search,
            selectedGroup.id,
          )
        }
        fetchQueryEnabled={!isGroupsFetching}
        setIsLoading={setIsPaginationLoading}
      />
    </Layout>
  );
}
