import { Fragment, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import adminAPI from '@/api/admin';
import Button, { BUTTON_KIND } from '@/components/Button';
import InputCheckbox from '@/components/InputCheckbox';
import Modal from '@/components/Modals/Modal';
import Search from '@/components/Search';
import MESSAGES from '@/constants/messages-en';
import classNames from '@/helpers/classNames';
import { CARD_STATUS } from '@/pages/CardsListPage';
import IProfile from '@/types/IProfile';

import Copy from './Icons/Copy';

interface ICardListItem {
  id: number;
  type?: string;
  activationKey?: string;
  insertedAt: Date;
  status: CARD_STATUS;
  cardStatus?: string;
  profile: IProfile;
  selected: boolean;
  cardPageURL: string;
  note: string;
  orderNumber?: string;
  checkItem: () => void;
  handleProgrammed: () => void;
  handleUnprogrammed: () => void;
  handleConnectProfile: (
    profileID: number | undefined,
    orgID: number | undefined,
  ) => void;
  handleEditNote: (admin_note: string | undefined) => void;
  handleUnlinkProfile: () => void;
  handleDelete: () => void;
}

export default function CardsListItem({
  id,
  type,
  activationKey,
  insertedAt,
  status,
  cardStatus,
  profile,
  selected,
  cardPageURL,
  note,
  orderNumber,
  checkItem,
  handleProgrammed,
  handleUnprogrammed,
  handleConnectProfile,
  handleEditNote,
  handleUnlinkProfile,
  handleDelete,
}: ICardListItem) {
  const history = useHistory();

  const [clipboardCopy, setClipboardCopy] = useState(false);
  const [profileSearch, setProfileSearch] = useState('');
  const [profileID, setProfileID] = useState<number | undefined>();
  const [orgID, setOrgID] = useState<number | undefined>();
  const [showOptions, setShowOptions] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isConnectProfileOpen, setIsConnectProfileOpen] = useState(false);
  const [isUnlinkProfileOpen, setIsUnlinkProfileOpen] = useState(false);
  const [profileData, setProfileData] = useState<IProfile[]>();
  const [adminNote, setAdminNote] = useState<string>(note || '');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const profileOrganisationID = profile?.organisation_id.toString();
  const profileOrganisationName = profile?.organisation_name;

  const listProfiles = useCallback(async (search: string) => {
    const { data } = await adminAPI.listProfiles({
      pageSize: 5,
      search,
    });

    setProfileData(
      data.data?.filter((item: IProfile) => item.first_name && item.last_name),
    );

    return data;
  }, []);

  const handleCancelEdit = () => {
    setAdminNote(note);
  };

  const handleSaveEdit = () => {
    setIsNoteOpen(false);
  };

  return (
    <li key={id}>
      <div className="block bg-white hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="self-start md:self-center">
            <InputCheckbox
              id={`Checkbox-${status}-${id}`}
              label=""
              value={selected}
              onChange={checkItem}
            />
          </div>
          <div className="min-w-0 flex-1 flex items-start md:items-center">
            <div
              className={classNames(
                'min-w-0 flex-1 md:grid md:grid-cols-3',
                status === 'unprogrammed' || status === 'programmed'
                  ? 'xl:grid-cols-7 pr-4'
                  : '',
                status === 'connected' ? 'xl:grid-cols-7' : '',
                status === 'pending_to_print' ? 'xl:grid-cols-8' : '',
                status === 'all' ? 'xl:grid-cols-8' : '',
              )}
            >
              <div className="flex items-center">
                <p className="text-sm text-gray-500 font-medium">#{id}</p>
              </div>
              <div className="flex items-center md:hidden xl:flex">
                {type && <p className="text-sm text-gray-900">{type}</p>}
              </div>
              <div className="flex items-center md:hidden xl:flex">
                <p className="text-sm text-gray-900">
                  {insertedAt.toLocaleString()}
                </p>
              </div>
              {status !== 'pending_to_print' && status !== 'connected' && (
                <div className="flex items-center md:hidden xl:flex">
                  {activationKey && (
                    <p className="text-sm text-gray-900">
                      Key #{activationKey}
                    </p>
                  )}
                </div>
              )}
              {status === 'all' && (
                <div className="flex items-center md:hidden xl:flex">
                  <p className="text-sm text-gray-900">{cardStatus}</p>
                </div>
              )}
              {['pending_to_print', 'connected', 'all'].includes(status) && (
                <div className="flex items-center md:hidden xl:flex">
                  {profile && (
                    <button
                      type="button"
                      onClick={() =>
                        history.push(`/edit-profile/${profile.id}`, {
                          fromName: 'Cards',
                          fromURL: '/',
                        })
                      }
                      className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
                    >
                      {[profile.first_name, profile.last_name]
                        .filter(v => v)
                        .join(' ')}
                      {(profile.first_name || profile.last_name) && <br />}
                      <span className="text-sm md:hidden">
                        Profile ID:&nbsp;
                      </span>
                      {`#${profile.id}`}
                    </button>
                  )}
                </div>
              )}
              {['pending_to_print', 'connected', 'all'].includes(status) && (
                <div className="flex items-center md:hidden xl:flex">
                  {profile && (
                    <button
                      type="button"
                      onClick={() =>
                        history.push(
                          `/view-organisation/${profileOrganisationID}`,
                          {
                            fromName: 'Cards',
                            fromURL: '/',
                          },
                        )
                      }
                      className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
                    >
                      {profileOrganisationName}
                      <br />
                      <span className="text-sm md:hidden">Org ID:&nbsp;</span>
                      {`#${profileOrganisationID}`}
                    </button>
                  )}
                </div>
              )}
              {status === 'pending_to_print' && (
                <div className="flex items-center md:hidden xl:flex">
                  {orderNumber && (
                    <Link
                      to={`/orders-v2/?search=${orderNumber}#all_orders`}
                      className="appearance-none text-sm leading-5 text-brand-500 text-left truncate"
                      target="_blank"
                    >
                      {`#${orderNumber}`}
                    </Link>
                  )}
                </div>
              )}
              {status !== 'connected' && (
                <div className="flex items-center md:hidden xl:flex justify-center">
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(cardPageURL)
                        .then(() => setClipboardCopy(true))
                        .finally(() =>
                          setTimeout(() => {
                            setClipboardCopy(false);
                          }, 2000),
                        );
                    }}
                  >
                    <Copy className="w-6 h-6 text-gray-400" />
                  </div>
                </div>
              )}
              {status === 'connected' && (
                <div className="flex items-center md:hidden xl:flex justify-center">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Unlink profile"
                    onClick={() => setIsUnlinkProfileOpen(true)}
                  />
                </div>
              )}
              {[CARD_STATUS.PENDING_TO_PRINT, CARD_STATUS.CONNECTED].includes(
                status,
              ) && (
                <div className="flex items-center md:hidden xl:flex justify-center">
                  <Button
                    kind={BUTTON_KIND.WHITE}
                    buttonText="Delete card & profile"
                    onClick={() => setIsDeleteOpen(true)}
                  />
                </div>
              )}
              <div
                className={classNames(
                  'mt-2 md:mt-0 col-span-2',
                  ['connected', 'all'].includes(status)
                    ? 'xl:col-span-1'
                    : 'xl:col-span-2',
                )}
              >
                <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 w-full h-full items-start sm:items-center xl:justify-end">
                  {status === 'unprogrammed' && (
                    <Button
                      kind={BUTTON_KIND.WHITE}
                      buttonText="Connect to Profile"
                      onClick={() => {
                        setIsConnectProfileOpen(true);
                        setShowOptions(true);
                      }}
                    />
                  )}
                  {status === 'programmed' && (
                    <Button
                      kind={BUTTON_KIND.WHITE}
                      buttonText="Connect to Profile"
                      onClick={() => {
                        setIsConnectProfileOpen(true);
                        setShowOptions(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {(status === 'unprogrammed' || status === 'programmed') && (
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <div className="flex items-center">
                  <Menu.Button className="appearance-none">
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1">
                      {status === 'unprogrammed' && (
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={handleProgrammed}
                            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                          >
                            Mark as unconnected
                          </button>
                        </Menu.Item>
                      )}
                      {status === 'programmed' && (
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={handleUnprogrammed}
                            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                          >
                            Mark as unprogrammed
                          </button>
                        </Menu.Item>
                      )}
                      {status === 'programmed' && (
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={() => setIsNoteOpen(true)}
                            className="text-gray-700 w-full text-left block px-4 py-2 text-sm"
                          >
                            Edit Note
                          </button>
                        </Menu.Item>
                      )}
                      {(status === 'unprogrammed' ||
                        status === 'programmed') && (
                        <Menu.Item>
                          <button
                            type="button"
                            onClick={() => setIsDeleteOpen(true)}
                            className="text-red-500 w-full text-left block px-4 py-2 text-sm"
                          >
                            Delete card
                          </button>
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}
          <Modal
            isOpen={isNoteOpen}
            setIsOpen={setIsNoteOpen}
            dialogTitle="Edit Card Note"
            dialogDescription="Card Note"
            successButtonText="Save"
            successButtonKind={BUTTON_KIND.PRIMARY}
            onSuccess={() => {
              handleEditNote(adminNote);
              handleSaveEdit();
            }}
            onCancel={() => {
              handleCancelEdit();
            }}
          >
            <textarea
              id="adminNote"
              name="adminNote"
              rows={3}
              className="shadow-sm focus:ring-brand-500 focus:border-brand-500 mt-1 block w-full text-base leading-6 border border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
              value={adminNote}
              onChange={e => setAdminNote(e.target.value)}
            />
          </Modal>
          <Modal
            isOpen={isDeleteOpen}
            setIsOpen={setIsDeleteOpen}
            dialogTitle="Warning: Deleting this card and profile is permanent."
            dialogDescription="By proceeding, you acknowledge that you will permanently delete this card and profile."
            successButtonText="Confirm"
            successButtonKind={BUTTON_KIND.RED}
            onSuccess={() => {
              handleDelete();
              setIsDeleteOpen(false);
            }}
            checkbox={deleteConfirmation}
            setCheckbox={setDeleteConfirmation}
            isDisabled={!deleteConfirmation}
            checkboxDescription="I am sure I want to delete."
          />
          <Modal
            isOpen={isConnectProfileOpen}
            setIsOpen={setIsConnectProfileOpen}
            dialogTitle={`Assign profile to card #${id}`}
            successButtonText="Assign profile"
            onSuccess={() => {
              handleConnectProfile(profileID, orgID);
              setIsConnectProfileOpen(false);
            }}
            isDisabled={!profileID}
          >
            <div className="mt-6 relative">
              <Search
                id={`ProfileList-1-20-date-desc--${profileSearch}`}
                label="Profile ID"
                placeholder="Search for Profile ID or Name"
                search={profileSearch}
                setSearch={value => {
                  if (value !== profileSearch) {
                    setProfileID(undefined);
                    setOrgID(undefined);
                    setShowOptions(true);
                  }
                  setProfileSearch(value);
                }}
                fetchQuery={search => listProfiles(search)}
              />
              {profileSearch && showOptions && (
                <div className="origin-top-left absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div className="py-1">
                    {profileData && profileData?.length ? (
                      profileData?.map((item: IProfile) => (
                        <button
                          type="button"
                          key={item.id}
                          className="appearance-none px-3 py-2 cursor-pointer hover:bg-gray-200 w-full text-left"
                          onClick={() => {
                            setProfileID(item.id);
                            setOrgID(item.organisation_id);
                            setShowOptions(false);
                            setProfileSearch(`${item.id}`);
                          }}
                        >
                          #{item.id}{' '}
                          <span className="text-gray-500">
                            {item.first_name} {item.last_name}
                          </span>
                        </button>
                      ))
                    ) : (
                      <li className="px-3 py-2 text-center">
                        No matching items found
                      </li>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            isOpen={isUnlinkProfileOpen}
            setIsOpen={setIsUnlinkProfileOpen}
            dialogTitle={MESSAGES.card.unlink.heading}
            dialogDescription={MESSAGES.card.unlink.description}
            successButtonText="Unlink card"
            onSuccess={() => {
              handleUnlinkProfile();
              setIsUnlinkProfileOpen(false);
            }}
          />
        </div>
      </div>
    </li>
  );
}
