import { useState } from 'react';

import { LightBulbIcon, PaperClipIcon } from '@heroicons/react/outline';

import filesAPI from '@/api/files';
import ssoApi, { ISamlConfig } from '@/api/sso';
import Button, { BUTTON_KIND } from '@/components/Button';
import useAuth from '@/hooks/useAuth';
import IFile from '@/types/IFile';

import { ErrorAlert, SuccessAlert } from '../Alert';
import { CopyableTextInput } from '../CopyableTextInput';
import FileUploadButtonComponent from '../FileUploadButton';
import Modal from '../Modals/Modal';
import ToggleField from '../ToggleField';

const ssoEntityId = 'urn:tapt.io:saml-sso';

type SsoProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  samlConfig?: ISamlConfig;
};

export function SsoConfigConnect({ isOpen, setIsOpen, samlConfig }: SsoProps) {
  const { orgID } = useAuth();

  const [isSsoEnabled, setIsSsoEnabled] = useState(false);
  const [federationXmlFile, setFederationXmlFile] = useState<IFile | undefined>(
    undefined,
  );
  const [federationXmlError, setFederationXmlError] = useState<
    string | undefined
  >(undefined);
  const [uploadingFederationXml, setUploadingFederationXml] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState<string | undefined>(undefined);

  async function onFileSelected(file: File) {
    if (orgID === undefined) {
      return;
    }

    // svgs are basically xmls, there is no better way to avoid them being uploaded
    if (file.type !== 'text/xml') {
      setFederationXmlError('Invalid format. Please upload .xml files only.');
      return;
    }

    setUploadingFederationXml(true);
    setFederationXmlError(undefined);

    const { data: uploadedFile } = await filesAPI.createAndUploadFile(
      orgID,
      new File([file], file.name),
    );

    setFederationXmlFile(uploadedFile.data);
    setUploadingFederationXml(false);
  }

  async function onSave() {
    if (orgID === undefined) {
      return;
    }

    if (federationXmlFile === undefined) {
      setFederationXmlError('Please upload a federation XML file');
      return;
    }

    try {
      setIsSaving(true);

      await ssoApi.updateSamlConfig(orgID, {
        is_active: isSsoEnabled,
        metadata_file_id: federationXmlFile.id,
      });

      setSaveSuccess('SSO configuration saved successfully');

      setTimeout(() => {
        setSaveSuccess(undefined);
        setIsOpen(false);
      }, 1000);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Single Sign-On SAML"
      dialogDescription={
        <>
          <span className="text-sm text-gray-500">
            Secure your organization by enabling Single Sign-On (SSO) with Tapt.
          </span>
          <br />
          <br />
          <a
            href="https://help.tapt.io/en/articles/9291289-azure-saml"
            className="underline"
            target="_blank"
          >
            See documentation here.
          </a>
        </>
      }
      successButton={
        <Button
          className="flex-1 md:flex-none"
          buttonText="Save"
          disabled={!isSsoEnabled || !federationXmlFile}
          loading={isSaving}
          onClick={onSave}
        />
      }
      onSuccess={() => {}}
      onCancel={() => {
        setIsSsoEnabled(samlConfig?.is_active || false);
        setFederationXmlFile(samlConfig?.metadata_file);
      }}
    >
      <div className="flex flex-row items-center bg-brand-100 rounded-md p-2 gap-2 mt-3">
        <div className="flex-shrink-0">
          <LightBulbIcon className="w-5 h-5 text-brand-500" />
        </div>
        <span className="text-brand-900 font-medium text-sm">
          Switch 'Active' toggle on and upload XML file to save.
        </span>
      </div>
      <div className="max-w-xl mt-4">
        <ToggleField
          label="Active"
          enabled={isSsoEnabled}
          setter={setIsSsoEnabled}
        />
      </div>
      <div className="max-w-xl mt-4">
        <label
          htmlFor="entity_id"
          className="block text-sm font-medium text-gray-700"
        >
          Entity ID
        </label>
        <CopyableTextInput
          value={samlConfig?.identifier || ''}
          copy={ssoEntityId}
        />
      </div>
      <div className="max-w-xl mt-4">
        <label
          htmlFor="reply-url"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Reply URL (Assertion Consumer Service URL)
        </label>
        <CopyableTextInput
          value={samlConfig?.reply_url || ''}
          copy={samlConfig?.reply_url || ''}
        />
      </div>
      <div className="max-w-xl mt-6">
        <label
          htmlFor="reply-url"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Federation Metadata XML
        </label>
        <div className="flex mt-0 pt-0">
          <div className="border-2 border-dashed border-gray-300 rounded-md flex w-full items-center justify-center cursor-pointer bg-gray-50 h-36">
            <FileUploadButtonComponent
              fileFormat=".xml"
              filename={federationXmlFile?.file?.file_name}
              url={federationXmlFile?.original_url}
              onFileSelected={onFileSelected}
              loading={uploadingFederationXml}
            />
          </div>
        </div>
        {federationXmlError && (
          <div className="mt-3">
            <ErrorAlert message={federationXmlError} />
          </div>
        )}
        {federationXmlFile && federationXmlFile.file?.file_name && (
          <div className="text-sm leading-5 text-brand-500 p-2 flex flex-row items-center justify-between">
            <div className="text-brand-500 flex flex-row">
              <PaperClipIcon className="w-5" />
              <span className="w-3/4 truncate no-wrap">
                {federationXmlFile.file.file_name}
              </span>
            </div>
            <a
              className="underline text-gray-400 cursor-pointer"
              onClick={() => setFederationXmlFile(undefined)}
            >
              Remove file
            </a>
          </div>
        )}
        {saveSuccess && <SuccessAlert message={saveSuccess} />}
      </div>
    </Modal>
  );
}

export function SsoConfigDisconnect({
  isOpen,
  setIsOpen,
  samlConfig,
}: SsoProps) {
  const { orgID } = useAuth();

  const [disconnectToggle, setDisconnectToggle] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  async function disconnectSso() {
    if (orgID === undefined || samlConfig === undefined) {
      return;
    }

    try {
      setIsDisconnecting(true);

      await ssoApi.updateSamlConfig(orgID, {
        is_active: false,
        metadata_file_id: samlConfig.metadata_file.id,
      });

      setSuccessMessage('SSO disconnected successfully');

      setTimeout(() => {
        setSuccessMessage(undefined);
        setIsOpen(false);
      }, 1000);
    } finally {
      setIsDisconnecting(false);
    }
  }

  return (
    <Modal
      dialogTitle="Are you sure you want to disconnect Single Sign-On SAML(SSO)?"
      dialogDescription="By proceeding, you acknowledge that you will be disconnecting SSO for your entire organisation? It can be reconfigured if you chose to do so again."
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSuccess={() => {}}
      cancelButtonText="Go back"
      successButton={
        <Button
          buttonText="Disconnect SSO"
          kind={BUTTON_KIND.RED}
          onClick={disconnectSso}
          loading={isDisconnecting}
          disabled={!disconnectToggle}
        />
      }
    >
      <div className="flex flex-row items-center gap-2 pt-4">
        <input
          id="sso-disconnect-checkbox"
          type="checkbox"
          className="w-4 h-4 border-gray-300 rounded focus:ring-brand-500 text-brand-500"
          checked={disconnectToggle}
          onChange={() => setDisconnectToggle(!disconnectToggle)}
        />
        <label
          className="text-sm text-gray-700"
          htmlFor="sso-disconnect-checkbox"
        >
          I am sure I want to disconnect SSO
        </label>
      </div>
      {successMessage && <SuccessAlert message={successMessage} />}
    </Modal>
  );
}
