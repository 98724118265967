import { useState } from 'react';

import scimApi from '@/api/scim';
import { CopyableTextInput } from '@/components/CopyableTextInput';
import useAuth from '@/hooks/useAuth';

import Button from '../Button';
import Modal from '../Modals/Modal';

export default function ActiveDirectory({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { orgID } = useAuth();
  const baseUrl = import.meta.env.VITE_SCIM_BASE_URL;

  const [secret, setSecret] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  async function generateToken() {
    if (orgID === undefined) {
      return;
    }

    setIsLoading(true);

    const res = await scimApi.createToken(orgID);

    setSecret(res.data.data.access_token);
    setIsLoading(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogTitle="Active Directory"
      dialogDescription={
        <>
          <span className="pb-2">
            System for Cross-Domain Identity Management (SCIM) allows you to
            automatically update, provision, and deprovision users.
            <br />
            <br />
            Connect your Active Directory to Tapt to automatically provision
            users and create profiles for them.
          </span>
          <br />
          <br />
          <a
            href="https://help.tapt.io/en/articles/9290224-scim-provisioning-of-users-using-azure-ad"
            className="underline"
            target="_blank"
          >
            See documentation here.
          </a>
        </>
      }
      cancelButtonText="Close"
      onClose={() => {
        setIsOpen(false);
        setSecret(undefined);
      }}
      onSuccess={() => {}}
    >
      <div className="max-w-xl mt-6">
        <label
          htmlFor="scim-base-url"
          className="block text-sm font-medium text-gray-700"
        >
          SCIM Base URL
        </label>
        <CopyableTextInput value={baseUrl} copy={baseUrl} />
      </div>
      <div className="pt-6 flex flex-row items-center justify-between">
        <label
          htmlFor="scim-token"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          SCIM Secret Token
        </label>
        {secret === undefined && (
          <Button
            buttonText="Generate Token"
            onClick={generateToken}
            loading={isLoading}
          />
        )}
      </div>
      {secret && <CopyableTextInput value={secret} copy={secret} />}
    </Modal>
  );
}
